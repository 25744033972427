import type { Moment } from "moment"
import type { RangeDate, ReportDepartmentsAndUsers } from "./interfaces"

type Ranges = { from: RangeDate; until: RangeDate }

export const yearToRangeDate = (year: number): Ranges => ({
  from: {
    day: 1,
    month: 0,
    year,
  },
  until: { day: 31, month: 11, year },
})

export const momentToRangeDate = (date: Moment): Ranges => ({
  from: {
    day: date.date(),
    month: date.month(),
    year: date.year(),
  },
  until: {
    day: date.date(),
    month: date.month(),
    year: date.year(),
  },
})

export const hasSelectedEmployees = (
  departmentsAndEmployees: ReportDepartmentsAndUsers
): boolean =>
  Object.values(departmentsAndEmployees).find(
    employees => employees.length > 0
  ) !== undefined
