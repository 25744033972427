import React from "react"
import { isoStringFromDate } from "../../../ContractApp/lib/dateUtils"
import { addDaysToISODateString, isoDateStringToJSDate } from "@mina-works/datetime-utils"
import { useIntl } from "react-intl"
import plannerPoolService from "../../configuration/PlannerPoolService"
import type DateContext from "../interfaces/DateContext"
import type MobilePool from "./MobilePool"
import type PlannerData from "../../configuration/PlannerData"
import type { PoolEntryWithUsageInfo } from "../interfaces/DateContext"
import type PoolEntry from "../../../commonInterfaces/PoolEntry"

const DATEUPDATEINTERVAL = 1000 // TODO: Increase after test period
const NUMBEROFDAYS = 28

interface Props {
  employeeId?: string
  departmentId?: string
  forceReload?: number
  plannerData?: PlannerData
}

export default function useMobilePool(
  { forceReload, departmentId, plannerData, employeeId }: Props
): MobilePool {
  const intl = useIntl()
  const [availableDates, setAvailableDates] = React.useState<DateContext[]>([])
  const [claimedDates, setClaimedDates] = React.useState<DateContext[]>([])
  const [date, setDate] = React.useState<string>(isoStringFromDate())
  const addClaimedByMeInfo = React.useMemo(
    () => makeAddClaimedByMeInfo(departmentId, employeeId, plannerData),
    [departmentId, employeeId, plannerData]
  )
  React.useEffect(() => {
    const handler = () => setDate(d => {
      const newDate = isoStringFromDate()
      if (newDate !== d) {
        return newDate
      } else {
        return d
      }
    })
    const interval = window.setInterval(handler, DATEUPDATEINTERVAL)
    return () => window.clearInterval(interval)
  }, [])
  const qualificationIds = React.useMemo(() => {
    if (employeeId && departmentId) {
      const employees = plannerData?.getPlannerV3()?.employeeCalendarsByOrganisationalUnit[departmentId]
      if (employees) {
        return employees.find(e => e.id === employeeId)?.qualificationIds ?? []
      }
    }
    return []
  }, [departmentId, employeeId, plannerData])
  React.useEffect(() => {
    const handler = async () => {
      let d = date
      if (d && departmentId) {
        const pool = await plannerPoolService.getPools([departmentId], d, NUMBEROFDAYS)
        const newAvailableDates: DateContext[] = []
        const newClaimedDates: DateContext[] = []
        for (let i = 0; i < NUMBEROFDAYS; i++) {
          const loopDate = d
          const poolDept = pool[departmentId]
          const entriesFromPool = poolDept.reduce((acc, e) => ([...acc, ...(e.entries)]), <PoolEntry[]>[])
          const entries = entriesFromPool?.filter(e => e.date === loopDate) ?? []
          if (entries.length > 0) {
            const entriesWithClaimedInfo = entries
              .filter(e => e.status !== "removed")
              .map(addClaimedByMeInfo)
            const principallyAvailableEntries = entriesWithClaimedInfo
              .filter(e => e.status === 'available' && !e.claimedByMe)
            const entriesWithTheRightQualifications = principallyAvailableEntries
              .filter(
                e => e.qualificationIds.length === 0
                  || (e.qualificationIds.some(qId => qualificationIds.includes(qId)))
              )
            const availableEntries = entriesWithTheRightQualifications
              .filter(e => !plannerData?.hasHolidayOrLoggedDayOn(employeeId, departmentId, e.date))
            const claimedEntries = entriesWithClaimedInfo
              .filter(e => e.claimedByMe)
              .filter(e => !plannerData?.hasHolidayOn(employeeId, departmentId, e.date))
            const common = {
              date: d,
              relativeDateForDisplay: `${i === 0
                ? intl.formatMessage({ id: 'pool-today' })
                : i === 1
                  ? intl.formatMessage({ id: 'pool-tomorrow' })
                  : i === 2
                    ? intl.formatMessage({ id: 'pool-day-after-tomorrow' })
                    : intl.formatMessage({ id: 'pool-in-n-days' }, { n: i })}`,
              dateForDisplay: isoDateStringToJSDate(d).toLocaleDateString(
                intl.locale,
                {
                  weekday: 'short',
                  day: 'numeric',
                  month: '2-digit',
                  year: 'numeric',
                }
              )
            }
            newAvailableDates.push({
              ...common,
              entries: availableEntries
            })
            newClaimedDates.push({
              ...common,
              entries: claimedEntries
            })
          }
          d = addDaysToISODateString(d, 1)
        }
        setAvailableDates(newAvailableDates)
        setClaimedDates(newClaimedDates)
      }
    }
    void handler()
  }, [addClaimedByMeInfo, plannerData, date, employeeId, departmentId, forceReload, intl, qualificationIds])
  const claimedDatesNumber = React.useMemo(() => {
    let n = 0
    for (const c of claimedDates) {
      n += c.entries.filter(e => e.claimedByMe).length
    }
    return n
  }, [claimedDates])
  const availableDatesNumber = React.useMemo(() => {
    let n = 0
    for (const c of availableDates) {
      n += c.entries.length
    }
    return n
  }, [availableDates])
  return {
    claimedDatesNumber, availableDatesNumber,
    claimedDates, availableDates,
  }
}

const makeAddClaimedByMeInfo = (
  departmentId?: string,
  employeeId?: string,
  plannerData?: PlannerData
) => (e: PoolEntry): PoolEntryWithUsageInfo => {
  if (
    departmentId === undefined
    || employeeId === undefined
    || plannerData === undefined
  ) {
    return e
  }
  const claimedByMe = !!plannerData
    ?.getPlannerV3()
    ?.employeeCalendarsByOrganisationalUnit[departmentId]
    ?.find(employee => employee.id === employeeId)
    ?.entries
    ?.find(
      entry => entry.startDate === e.date
        && entry.poolReference?.identifier === e.identifier
    )
  // claimedByMe ||= !!plannerData
  //   ?.getPlannerV3()
  //   ?.employeeCalendarsByOrganisationalUnit[departmentId]
  //   ?.find(employee => employee.id === employeeId)
  //   ?.supersededEntries
  //   ?.find(
  //     entry => entry.startDate === e.date
  //       && entry.poolReference?.identifier === e.identifier
  //   )
  return { ...e, claimedByMe }
}