import type { Action, ActionCreator, Dispatch } from "redux"
import type { ThunkAction } from "redux-thunk"

import {
  showGlobalMessage,
  convertBackendError,
} from "../../../../modules/globalMessage/controller"
import { fetchREST } from "../../../../shared/rest.js"
import { encodeURIComponentsForActions } from "../../../../shared/uriUtils"

import { pad00 } from "../../../legacy/time-utils"

import type {
  Department,
  RangeDate,
  ReportDepartmentsAndUsers,
} from "../interfaces"

import types from "./actionTypes"

export const setSelectedDepartmentsAndUsers: ActionCreator<Action> = (
  departmentsAndUsers: ReportDepartmentsAndUsers
) => ({
  type: types.SETSELECTEDDEPARTMENTSANDUSERS,
  departmentsAndUsers,
})

const loadDepartmentsAndUsers: ActionCreator<Action> = (
  departments: Department[]
) => ({
  type: types.LOADREPORTDEPARTMENTSANDUSERS,
  departments,
})

export const loadReportDepartmentsAndUsers: ActionCreator<
  ThunkAction<Promise<Action>, any, any, any>
> = (
  includeHiddenEmployees: boolean,
  tz: string,
  from?: RangeDate,
  until?: RangeDate
) => {
  return async (dispatch: Dispatch, getState): Promise<Action> => {
    try {
      // TODO: Cleanup backend to something neutral
      const res = await fetchREST(
        "/reports/departmentsAndUsers?" +
          encodeURIComponentsForActions({
            includeHiddenEmployees,
            tz,
            from: rangeDateToISO(from),
            until: rangeDateToISO(until),
          }),
        undefined,
        { state: getState() }
      )
      const json = await res.json()
      if (json.error) {
        return showGlobalMessage(dispatch, convertBackendError(json))
      }
      return dispatch(loadDepartmentsAndUsers(json))
    } catch (err) {
      console.log(err)
    }
  }
}

const loadOneOffDepartmentsAndUsers: ActionCreator<Action> = (
  departments: Department[]
) => ({
  type: types.LOADREPORTONEOFFDEPARTMENTSANDUSERS,
  departments,
})

export const loadReportOneOffDepartmentsAndUsers: ActionCreator<
  ThunkAction<Promise<Action>, any, any, any>
> = (includeHiddenEmployees: boolean, from?: RangeDate, until?: RangeDate) => {
  return async (dispatch: Dispatch, getState): Promise<Action> => {
    try {
      // TODO: Cleanup backend to something neutral
      const res = await fetchREST(
        "/reports/oneOffEmployees?" +
          encodeURIComponentsForActions({
            includeHiddenEmployees,
            from: rangeDateToISO(from),
            until: rangeDateToISO(until),
          }),
        undefined,
        { state: getState() }
      )
      const json = await res.json()
      if (json.error) {
        return showGlobalMessage(dispatch, convertBackendError(json))
      }
      return dispatch(loadOneOffDepartmentsAndUsers(json))
    } catch (err) {
      console.log(err)
    }
  }
}

const rangeDateToISO = (rangeDate?: RangeDate): string | undefined => {
  return rangeDate
    ? `${rangeDate.year}-${pad00(rangeDate.month + 1)}-${pad00(rangeDate.day)}`
    : undefined
}
