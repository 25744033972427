import * as React from "react"
import { useIntl } from "react-intl"
import PlannerTime from "../../../shared/plannerDateTime/PlannerTime"
import { PunchAPI } from "../../configuration/PunchAPI"
import type PunchedStep from "../../configuration/PunchedStep"

interface Props {
  step: PunchedStep
  /**
   * NOTE: steps is REVERSED – the first step is the final entry!
   */
  steps: PunchedStep[]
  stepNumber: number
  update: () => Promise<void>
}

export default function useStepCallbacks({
  step, steps, stepNumber, update
}: Props): {
  illegalTime: boolean
  edit: boolean
  updateTimeForModify: (v: PlannerTime) => void
  editBtnCallback: () => void
  deleteBtnCallback: () => void
  save: () => void
} {
  const [edit, setEdit] = React.useState(false)
  const [illegalTime, setIllegalTime] = React.useState(false)
  const [timeForModify, setTimeForModify] = React.useState<
    PlannerTime | undefined
  >()
  const intl = useIntl()
  const close = React.useCallback(() => setEdit(false), [])
  const updateTimeForModify = React.useCallback(
    (v: PlannerTime) => {
      const previousStepIdx = stepNumber + 1
      const previousStep = steps[previousStepIdx]
      const firstStepIdx = steps.length - 1
      const isFirstStep = stepNumber === firstStepIdx
      const isSecondStep = stepNumber === firstStepIdx - 1 // XXX was +1, probably reversed by accident
      const firstStep = steps[firstStepIdx]
      const firstStepTime = PlannerTime.fromObject(firstStep?.time)
      const prevStepTime = PlannerTime.fromObject(previousStep?.time)
      const thisAfterPrevious = prevStepTime.lt(v)
      const thisBeforePrevious = prevStepTime.gt(v)
      const thisAfterFirst = firstStepTime.lt(v)
      const thisBeforeFirst = firstStepTime.gt(v)
      const prevAfterFirst = firstStepTime.lt(prevStepTime)
      const prevBeforeFirst = firstStepTime.gt(prevStepTime)
      const simpleCase =
        thisAfterFirst && thisAfterPrevious && (isSecondStep || prevAfterFirst)
      const overnightEverythingElseFirstDay =
        !isSecondStep &&
        thisBeforeFirst &&
        thisBeforePrevious &&
        (isSecondStep || prevAfterFirst)
      const overnightSecondDay =
        !isSecondStep && thisBeforeFirst && thisAfterPrevious && prevBeforeFirst
      const secondStepOvernight = isSecondStep && thisBeforeFirst
      if (
        isFirstStep ||
        !previousStep ||
        secondStepOvernight ||
        simpleCase ||
        overnightEverythingElseFirstDay ||
        overnightSecondDay
      ) {
        setTimeForModify(v)
        setIllegalTime(false)
      } else {
        setIllegalTime(true)
      }
    },
    [steps, stepNumber]
  )
  const editBtnCallback = React.useCallback(() => {
    setEdit(e => !e)
  }, [])
  const deleteBtnCallback = React.useCallback(() => {
    if (window.confirm(intl.formatMessage({ id: "reallyDeleteStep" }))) {
      void new PunchAPI()
        .deletePunchedStep(step.id, step.date)
        .then(update)
        .then(close)
    }
  }, [intl, step.id, step.date, update, close])
  const save = React.useCallback(() => {
    if (timeForModify) {
      void new PunchAPI()
        .modifyPunchedStep(
          step.id,
          step.date,
          timeForModify.getHour(),
          timeForModify.getMinute()
        )
        .then(success => {
          if (success) {
            return update()
          } else {
            alert(intl.formatMessage({ id: 'warnIllegalPunchTime' }))
          }
        })
        .then(close)
    }
  }, [close, intl, step.date, step.id, timeForModify, update])
  return {
    edit, updateTimeForModify, illegalTime,
    editBtnCallback, deleteBtnCallback, save
  }
}

// TODO: Check is in the future here as well
// NOTE: DO take timezones into account by calculating the offset
// based on the first entry