export const ensureIntOrUndefined = (
  val: string | number | null | undefined
): number | undefined => {
  const trimmed = typeof val === "string" ? val.trim() : val
  if (
    trimmed === undefined ||
    trimmed === "undefined" ||
    trimmed === null ||
    trimmed === "null" ||
    trimmed === ""
  ) {
    return
  } else {
    if (typeof trimmed === "string") {
      if (isNaN(Number(trimmed))) {
        return
      }
      try {
        // filter out float
        const parsed = parseInt(trimmed, 10)
        if (parsed.toString() === trimmed) {
          return parsed
        } else {
          return
        }
      } catch (error) {
        console.error("Trying to parse invalid value", error)
        return
      }
    } else {
      // filter out float
      if (trimmed === parseInt(trimmed.toString(), 10)) {
        return trimmed
      } else {
        return
      }
    }
  }
}
