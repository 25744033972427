import { ensureIntOrUndefined } from "../../../../shared/ensureInt"
import { valueAt } from "../../../../shared/walkObjectHierarchy"

import types from "../actions/actionTypes"

export default function loginReducer(state = {}, action) {
  switch (action.type) {
    case types.NETWORKVERYSLOW: // effectively LOGIN
      return state?.login?.user?.id !== action?.user?.id &&
        state?.login?.networkIsSlow !== true
        ? {
            ...state,
            networkIsSlow: true,
            user: parseUser(action.user),
            isLoggedIn: !!action.user,
            loginFailed: false,
            loginError: undefined,
            openDialog: false, // automatically handled as well!
          }
        : state
    case types.LOGIN:
      return valueAt(state, ["login", "user", "id"]) !==
        (action && action.user && action.user.id)
        ? {
            ...state,
            networkIsSlow: false,
            user: parseUser(action.user),
            isLoggedIn: !!action.user,
            loginFailed: false,
            loginError: undefined,
            openDialog: false, // automatically handled as well!
          }
        : state
    case types.LOGOUT:
      return {
        ...state,
        user: {},
        isLoggedIn: false,
      }
    case types.OPENLOGINDIALOG:
      return {
        ...state,
        openDialog: true,
      }
    case types.CLOSELOGINDIALOG:
      return {
        ...state,
        openDialog: false,
        loginFailed: false,
        loginError: undefined,
      }
    case types.LOGINFAILED:
      return {
        ...state,
        loginFailed: true,
        loginError: action.error,
      }
    case types.OPENPASSWORDRESETDIALOG:
      return {
        ...state,
        openDialog: false, // close the login dialog
        openPasswordResetDialog: true,
        initialResetUsername: action.username, // small user convenience
        passwordResetError: undefined,
      }
    case types.CLOSEPASSWORDRESETDIALOG:
      return {
        ...state,
        openPasswordResetDialog: false,
        initialResetUsername: undefined,
        passwordResetError: undefined,
      }
    case types.APPENDPASSWORDRESETERROR:
      return {
        ...state,
        passwordResetError: action.error,
      }
    default:
      return state
  }
}

const parseUser = user => {
  return {
    ...user,
    clientId: ensureIntOrUndefined(user.clientId),
    orgunitId: ensureIntOrUndefined(user.orgunitId),
  }
}
