import type { Entry } from "../configuration/PlannedDay"

const cmpEntries = (a: Entry, b: Entry): number => {
  if (a.getStartDate() < b.getStartDate()) {
    return -1
  } else if (a.getStartDate() > b.getStartDate()) {
    return 1
  } else if (
    a.getStartTime() !== undefined &&
    (b.getStartTime === undefined || a.getStartTime()! < b.getStartTime()!)
  ) {
    return -1
  } else if (
    b.getStartTime() &&
    (a.getStartTime() === undefined || b.getStartTime()! < a.getStartTime()!)
  ) {
    return 1
  }
  return 0
}

export { cmpEntries }
