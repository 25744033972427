/**
 * Please note: This use of thunks to access the state of other slices
 * (in this case, the orgUnit module) should be a rare exception.
 *
 * In this case we do not really want a shared parent for the reducers
 * (because the widget should remain simple). A thunk with getState works
 * perfectly, especially since we have to load the org units anyway, but it *is*
 * frowned upon as a solution (but only by some, and there are also
 * developers that would discount thunks entirely).
 *
 * We should be more concerned with the fact that we're importing specific
 * module code in a shared module (but this also seems fine in this case.)
 *
 * TODO: *Maybe* parts of the orgUnit manager should be shared.
 */
import { fetchREST } from "../../../../shared/rest.js"
import { resetStore } from "../../../../redux/actions/rootActions"

import types from "./actionTypes"

const s = arr => arr.sort((a, b) => (a.id <= b.id ? -1 : 1))

export const setContext = (clientId, orgunitId) => (dispatch, getState) => {
  clientId = clientId !== "undefined" ? clientId : undefined
  orgunitId = orgunitId !== "undefined" ? orgunitId : undefined
  if (shouldResetStore(clientId, orgunitId, getState)) {
    dispatch(resetStore())
  }
  return dispatch(_selectClientId(clientId, orgunitId))
}

export const selectClient =
  (clientId, setOrgunit = true) =>
  (dispatch, getState) => {
    clientId = clientId !== "undefined" ? clientId : undefined
    if (setOrgunit) {
      return dispatch(loadAllActiveOrgUnitsAsync(clientId)).then(orgUnits => {
        const orgUnitId = orgUnits
          .filter(ou => ou.isRoot === true)
          .reduce((id, ou) => (id = ou.id), 0)
        if (shouldResetStore(clientId, orgUnitId, getState)) {
          dispatch(resetStore())
        }
        return dispatch(_selectClientId(clientId, orgUnitId))
        // return dispatch(selectOrgUnit(orgUnitId, clientId))
      })
    } else {
      if (shouldResetStore(clientId, "dummy", getState)) {
        dispatch(resetStore())
      }
      return dispatch(_selectClientId(clientId))
    }
  }

export function _selectClientId(clientId, orgunitId) {
  orgunitId = !clientId || orgunitId === 0 ? undefined : orgunitId
  return { type: types.SELECTCLIENT, clientId, orgunitId }
}

export const selectOrgUnit = orgunitId => dispatch => {
  dispatch(selectOrgUnitSync(orgunitId))
}

/**
 * Use "dummy" for orgunitId if none is available in the
 * action creator; the orgunitId will then be ignored
 */
function shouldResetStore(clientId, orgunitId, getState) {
  const state = getState()
  const res =
    (orgunitId !== "dummy" ||
      "" + orgunitId !== "" + state.oucontext.currentOrgUnitId) &&
    "" + clientId !== "" + state.oucontext.currentClientId &&
    state.oucontext.currentClientId !== undefined &&
    state.oucontext.currentClientId !== "undefined" &&
    state.oucontext.currentOrgUnitId !== undefined &&
    state.oucontext.currentOrgUnitId !== "undefined"
  return res
}

export const selectOrgUnitSync = orgunitId => ({
  type: types.SELECTORGUNIT,
  orgunitId,
})

export function loadAllActiveOrgUnitsAsync(clientId) {
  if (!clientId) {
    return dispatch => {
      dispatch(loadAllActiveOrgUnits([]))
      return Promise.resolve([])
    }
  }
  return dispatch =>
    fetchREST(
      // TODO: replace this with a special call without extra data!
      "/orgUnit/getAllPublic?clientId=" +
        encodeURIComponent(clientId || 0) +
        "&includeArchived=false"
    )
      .then(res => res.json())
      .then(json => {
        if (json.error) {
          // TODO: Dispatch error message here
          json = []
        }
        json = s(json)
        dispatch(loadAllActiveOrgUnits(json))
        return Promise.resolve(json)
      })
      .catch(err => {
        console.log(err)
        return dispatch(loadAllActiveOrgUnitsFailed(err))
      })
}

function loadAllActiveOrgUnits(allOrgUnits) {
  return { type: types.LOADALLACTIVEORGUNITS, allOrgUnits }
}

function loadAllActiveOrgUnitsFailed(error) {
  return { type: types.LOADALLACTIVEORGUNITSFAILED, error }
}

export function loadAllActiveClientsAsync() {
  return dispatch =>
    fetchREST(
      // TODO: replace with special call (id, title)
      "/clientManager/getAllNames"
    )
      .then(res => res.json())
      .then(json => {
        if (json.error) {
          /* This rest call is in a subscriber...displaying an error message
           * every few seconds would be annoying. So just load an empty list.
           */
          dispatch(loadAllActiveClients([]))
          return Promise.resolve()
        }
        json = s(json)
        dispatch(loadAllActiveClients(json))
        return Promise.resolve(json)
      })
      .catch(err => {
        console.log(err)
        dispatch(loadAllActiveClientsFailed(err))
      })
}

function loadAllActiveClients(allClients) {
  return { type: types.LOADALLACTIVECLIENTS, allClients }
}

function loadAllActiveClientsFailed(error) {
  return { type: types.LOADALLACTIVECLIENTSFAILED, error }
}
