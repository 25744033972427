/**
 * The main menu's container component; handles changing the route
 */

import React, { Component } from "react"
import { connect } from "react-redux"
import { injectIntl } from "react-intl"
import {
  VIEWPLANNER,
  EDITPLANNER,
  VIEWOWNPLANNER,
  VIEWQUALIFICATIONS,
  EDITQUALIFICATIONS,
  VIEWACTIVITIES,
  EDITACTIVITIES,
  VIEWPBBTEMPLATES,
  EDITPBBTEMPLATES,
  EDITAUTOPLANS,
  VIEWORGCHART,
  EDITORGCHART,
  VIEWUSERSEMPLOYEESMASTERDATA,
  EDITUSERSEMPLOYEESMASTERDATA,
  EDITROLES,
  VIEWSETTINGS,
  EDITSETTINGS,
  VIEWCOLLECTIVEAGREEMENTS,
  EDITCOLLECTIVEAGREEMENTS,
  VIEWOWNMASTERDATA,
  VIEWOWNWORKHOURSACCOUNT,
  VIEWOWNLEAVEACCOUNT,
  VIEWUSERREPORTS,
  VIEWWORKCONTRACTS,
  EDITWORKCONTRACTS,
  EDITWORKHOURSACCOUNT,
  DELETEUSERSEMPLOYEES,
  ASSIGNQUALIFICATIONS,
  VIEWQUALIFICATIONSASSIGNMENTS,
  VIEWWORKHOURSACCOUNT,
  VIEWLEAVEACCOUNT,
  EDITLEAVEACCOUNT,
} from "../../shared/permissions"
import {
  hasPermission,
  hasOneOfPermissions,
} from "../../shared/permissionCheck/controller"
import withRouter from "../../shared/withRouter"
import {
  isLoggedIn,
  isAdmin,
  isUserOutsideClients,
  getLoggedInUserId,
  canManageAdmins,
} from "../login/controller"
import { getIsNetworkVerySlowState } from "../login/redux/selectors/loginSelectors"

import MainMenu from "./components/MainMenuComponent"
import { toggleMainMenu } from "./redux/mainMenuActions"
import { getMainMenuCollapsed } from "./redux/mainMenuSelectors"
import { APPMODULETOURL, URLENDTOAPPMODULE } from "./urls"
import ProcessList from "../../apps/commonConfiguration/processes/ProcessList"
import { getContext } from "../../shared/OUContext/controller"
import areFeaturesAvailable from "../../apps/shared/lib/featuresetUtils/areFeaturesAvailable"

import { permissionCheck } from "../reports/weeklySchedule/containers/CalendarReportsManagerContainer"

import { USERSSEENINTERVAL, VACAREQINTERVAL } from "./constants"

interface State {
  selectedKeys: string[]
  usersSeen?: number
  vacationBadge: number
  vacationRequestsAvailable: boolean
}

class MainMenuContainer extends Component<any, State> {
  private usersSeenInterval: number
  private vacationBadgeInterval: number

  constructor(props) {
    super(props)
    this.state = {
      selectedKeys: [],
      vacationBadge: 0,
      vacationRequestsAvailable: false,
    }
  }

  componentDidMount() {
    const appModule = this.pathToAppModule()
    this.changeMenuState(appModule)
    const runUsersSeenCheck = () =>
      void fetch("/rest/authentication/usersSeen")
        .then(res => res.json())
        .then(({ recentlySeenUsers }) =>
          this.setState({ usersSeen: recentlySeenUsers })
        )
    runUsersSeenCheck()
    this.usersSeenInterval = window.setInterval(
      runUsersSeenCheck,
      USERSSEENINTERVAL
    )
    this.setupVacationBadgeInterval({})
    void this.loadFeatureAvailableFlags()
  }

  componentDidUpdate(prevProps) {
    const appModule = this.pathToAppModule()
    if (this.state.selectedKeys.indexOf(appModule) === -1) {
      this.changeMenuState(appModule)
    } else {
      this.setupVacationBadgeInterval(prevProps)
    }
    if (
      this.props.oucontext?.clientId !== undefined &&
      this.props.oucontext?.clientId !== prevProps.oucontext?.clientId
    ) {
      void this.loadFeatureAvailableFlags()
    }
  }

  componentWillUnmount(): void {
    window.clearInterval(this.usersSeenInterval)
    window.clearInterval(this.vacationBadgeInterval)
  }

  pathToAppModule() {
    let moduleName = "home" // The default app module!
    Object.getOwnPropertyNames(URLENDTOAPPMODULE).forEach(urlEnd => {
      if (this.props.location.pathname.endsWith(urlEnd)) {
        moduleName = URLENDTOAPPMODULE[urlEnd]
      }
    })
    return moduleName
  }

  changeMenuState(appModule) {
    this.setState({
      selectedKeys: [appModule],
    })
  }

  render() {
    const changeAppModule = appmodule => {
      const url = APPMODULETOURL[appmodule]
      this.props.navigate(url)
    }
    const goHome = e => {
      e.preventDefault()
      changeAppModule("home")
    }
    // XXX: canViewAutoplan/canViewPBB because of the planer direct link.
    // But still needs the canViewUsers permission.
    // this.props.canViewUsers is duplicated on purpose!
    const canViewEmployeeTab =
      this.props.canViewUsers &&
      (this.props.canViewUsers ||
        this.props.canViewContracts ||
        this.props.canViewAutoplan ||
        this.props.canViewPBB)
    const canViewConfiguration =
      canViewEmployeeTab ||
      this.props.canUploadHolidays ||
      this.props.canViewCETs ||
      this.props.canViewCollectiveAgreements ||
      this.props.canViewOrgChart ||
      this.props.canViewQualifications ||
      this.props.canViewRoles ||
      this.props.canViewTimesheets
    const canViewUserReports =
      this.props.isAdmin ||
      this.props.canViewTimesheetReport ||
      this.props.canViewWeekplanReport ||
      this.props.canViewUnavailabilityReport ||
      this.props.canViewHourlyAnalysisReport
    return (
      <MainMenu
        vacationBadge={this.state.vacationBadge}
        loggedIn={this.props.loggedIn}
        selectedKeys={this.state.selectedKeys}
        changeAppModule={changeAppModule}
        onGoHome={goHome}
        networkIsVerySlow={this.props.networkIsVerySlow}
        isUserOutsideClients={this.props.isUserOutsideClients}
        usersSeen={this.state.usersSeen}
        canViewCalendar={this.props.canViewCalendar}
        canViewClients={this.props.canViewClients}
        canEditAdminAccess={this.props.canEditAdminAccess}
        isAdmin={this.props.isAdmin}
        canViewTelemetry={this.props.canViewTelemetry}
        canViewConfiguration={canViewConfiguration}
        canUploadHolidays={this.props.canUploadHolidays}
        canViewUserReports={canViewUserReports}
        canViewProcesses={
          this.props.canViewProcesses && this.state.vacationRequestsAvailable
        }
        canManageInfoMessages={this.props.canManageInfoMessages}
        selectedClient={this.props.oucontext.clientId}
      />
    )
  }

  private async loadFeatureAvailableFlags(): Promise<void> {
    const res = await areFeaturesAvailable({
      clientId: this.props.oucontext.clientId,
      featureTypes: ["VacationRequests"],
    })
    this.setState({
      vacationRequestsAvailable: res.VacationRequests?.available,
    })
  }

  private setupVacationBadgeInterval(prevProps: any) {
    if (this.props.oucontext?.orgunitId !== prevProps.oucontext?.orgunitId) {
      window.clearInterval(this.vacationBadgeInterval)
      this.vacationBadgeInterval = window.setInterval(
        () => void this.updateVacationBadge(),
        VACAREQINTERVAL
      )
      void this.updateVacationBadge()
    }
  }

  private async updateVacationBadge() {
    const processList = this.makeEmptyProcessList()
    const vacationBadge = await processList.loadManagerBadgeCount()
    if (this.state.vacationBadge !== vacationBadge) {
      this.setState({ vacationBadge })
    }
  }

  private makeEmptyProcessList(showAll = false) {
    const processList = new ProcessList(
      this.props.oucontext.orgunitId as string,
      this.props.oucontext.clientId as string,
      this.props.userId as string,
      this.props.locale as string,
      showAll
    )
    return processList
  }
}

const mapStateToProps = state => ({
  loggedIn: isLoggedIn(state),
  collapsed: getMainMenuCollapsed(state),
  networkIsVerySlow: getIsNetworkVerySlowState(state),
  canViewContracts: hasOneOfPermissions(
    [VIEWWORKCONTRACTS, EDITWORKCONTRACTS],
    state
  ),
  canViewTimesheets: hasOneOfPermissions(
    [
      VIEWWORKHOURSACCOUNT,
      EDITWORKHOURSACCOUNT,
      VIEWLEAVEACCOUNT,
      EDITLEAVEACCOUNT,
    ],
    state
  ),
  // TODO: Process permissions!
  canViewProcesses: hasOneOfPermissions(
    [EDITLEAVEACCOUNT, EDITWORKHOURSACCOUNT, EDITPLANNER],
    state
  ),
  canViewCalendar: hasOneOfPermissions(
    [VIEWPLANNER, EDITPLANNER, VIEWOWNPLANNER],
    state
  ),
  canViewQualifications: hasOneOfPermissions(
    [
      VIEWQUALIFICATIONS,
      EDITQUALIFICATIONS,
      ASSIGNQUALIFICATIONS,
      VIEWQUALIFICATIONSASSIGNMENTS,
    ],
    state
  ),
  canViewCETs: hasOneOfPermissions([VIEWACTIVITIES, EDITACTIVITIES], state),
  canViewPBB: hasOneOfPermissions([VIEWPBBTEMPLATES, EDITPBBTEMPLATES], state),
  canViewAutoplan: hasPermission(EDITAUTOPLANS, state),
  canViewOrgChart: hasOneOfPermissions([VIEWORGCHART, EDITORGCHART], state),
  canViewUsers: hasOneOfPermissions(
    [
      VIEWUSERSEMPLOYEESMASTERDATA,
      EDITUSERSEMPLOYEESMASTERDATA,
      DELETEUSERSEMPLOYEES,
    ],
    state
  ),
  canViewRoles: hasPermission(EDITROLES, state),
  canViewSettings: hasOneOfPermissions([VIEWSETTINGS, EDITSETTINGS], state),
  canViewClients: isUserOutsideClients(state),
  canManageInfoMessages: isAdmin(state),
  canViewTelemetry: isAdmin(state),
  canUploadHolidays: isAdmin(state),
  canViewCollectiveAgreements: hasOneOfPermissions(
    [VIEWCOLLECTIVEAGREEMENTS, EDITCOLLECTIVEAGREEMENTS],
    state
  ),
  canViewOwnData: hasOneOfPermissions(
    [VIEWOWNMASTERDATA, VIEWOWNWORKHOURSACCOUNT, VIEWOWNLEAVEACCOUNT],
    state
  ),
  canViewTimesheetReport:
    hasOneOfPermissions([VIEWWORKHOURSACCOUNT, EDITWORKHOURSACCOUNT], state) &&
    hasPermission(VIEWUSERREPORTS, state),
  canViewWeekplanReport: permissionCheck(state),
  canViewUnavailabilityReport:
    hasOneOfPermissions([VIEWLEAVEACCOUNT, EDITLEAVEACCOUNT], state) &&
    hasPermission(VIEWUSERREPORTS, state),
  canViewHourlyAnalysisReport: hasPermission(VIEWUSERREPORTS, state),
  canEditAdminAccess: canManageAdmins(state),
  isAdmin: isAdmin(state),
  userId: getLoggedInUserId(state),
  isUserOutsideClients: isUserOutsideClients(state),
  oucontext: getContext(state),
})

const mapDispatchToProps = dispatch => ({
  onToggle: () => dispatch(toggleMainMenu()),
})

const connectReduxStore = connect(mapStateToProps, mapDispatchToProps)
const MainMenuContainerComponent = injectIntl<any, any>(
  connectReduxStore(withRouter(MainMenuContainer))
)

export default MainMenuContainerComponent
