/**
 * Get the number of processes for which an action is required
 */

export default async function getNumProcessesActionRequired(
  typeName: string,
  departmentId: string
): Promise<number> {
  if (!departmentId || departmentId === "") {
    return 0
  }
  const res = await fetch(
    makeURL("/rest/workflows/getNumActionRequired", departmentId ?? ""),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
  )
  const num = (await res.json()) as number | undefined
  return num ?? 0
}

function makeURL(path: string, departmentId: string) {
  const url =
    path +
    "?" +
    new URLSearchParams({
      orgunitId: departmentId,
      contextOrgunitId: departmentId,
    }).toString()
  return url
}
