/**
 * Selector model for the context UI
 */
import { createSelector } from "reselect"
import { valueAt } from "../../../../shared/walkObjectHierarchy"
import {
  getUserIsAdminState,
  getUserIsRestrictedAdminState,
  getUserIsEnhancedState,
  getUserClientId,
  getUserOrgunitId,
} from "../../../../modules/login/redux/selectors/loginSelectors"
import Hierarchy from "../../../models/Hierarchy"

export const getCurrentClientIdState = createSelector(
  state => valueAt(state, ["oucontext", "currentClientId"], undefined),
  getUserIsAdminState,
  getUserIsRestrictedAdminState,
  getUserIsEnhancedState,
  getUserClientId,
  (
    ouClientId,
    userIsAdmin,
    userIsRestrictedAdmin,
    userIsEnhanced,
    userClientId
  ) => {
    if (
      ouClientId !== undefined ||
      userIsAdmin ||
      userIsRestrictedAdmin ||
      userIsEnhanced
    ) {
      return ouClientId
    }
    return userClientId
  }
)

export const getCurrentOrgUnitIdState = createSelector(
  state => valueAt(state, ["oucontext", "currentOrgUnitId"], undefined),
  getUserIsAdminState,
  getUserIsRestrictedAdminState,
  getUserIsEnhancedState,
  getUserOrgunitId,
  (
    ouOrgunitId,
    userIsAdmin,
    userIsRestrictedAdmin,
    userIsEnhanced,
    userOrgunitId
  ) => {
    if (
      ouOrgunitId !== undefined ||
      userIsAdmin ||
      userIsRestrictedAdmin ||
      userIsEnhanced
    ) {
      return ouOrgunitId
    }
    return userOrgunitId
  }
)

export const getAllActiveOrgUnitsByIdState = state => getById(state, "orgUnits")
export const getAllActiveOrgUnitsState = createSelector(
  getAllActiveOrgUnitsByIdState,
  byId => (byId !== undefined ? Object.values(byId) : undefined)
)

const getAllActiveClients = state => getById(state, "clients")
export const getAllActiveClientsState = createSelector(
  getAllActiveClients,
  byId => (byId !== undefined ? Object.values(byId) : undefined)
)

export const getHierarchy = createSelector(
  getAllActiveOrgUnitsByIdState,
  (byId = {}) => Hierarchy.createHierarchy(Object.values(byId))
)

export const getContextNode = createSelector(
  getCurrentClientIdState,
  getCurrentOrgUnitIdState,
  getAllActiveClients,
  getHierarchy,
  (clientId, orgunitId, clientsById, hierarchy) => {
    return hierarchy && hierarchy.find(orgunitId)
  }
)

export const getContextState = createSelector(
  getCurrentClientIdState,
  getCurrentOrgUnitIdState,
  getAllActiveClients,
  getAllActiveOrgUnitsByIdState,
  (clientId, orgunitId, clientsById, orgunitsById) => {
    const hierarchy = orgunitsById
      ? Hierarchy.createHierarchy(Object.values(orgunitsById))
      : undefined
    const node = hierarchy && hierarchy.find(orgunitId)
    const nextLocation = node ? node.findNearestLocation() : undefined
    return {
      clientId,
      orgunitId,
      nextLocationName: nextLocation ? nextLocation.getFields().name : "",
      clientName: valueAt(clientsById, [clientId, "name"]) || "",
      orgunitName: valueAt(orgunitsById, [orgunitId, "name"]) || "",
    }
  }
)

function getById(state, subslice = "orgUnits") {
  return valueAt(state, ["oucontext", subslice, "byId"])
}
