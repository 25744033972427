import * as React from "react"
import { useIntl } from "react-intl"
import type { Entry } from "../configuration/PlannedDay"
import type PlannedDay from "../configuration/PlannedDay"

// **********************************************************************
// TODO: The data structure for entries is problematic:
// It used to be exactly one entry long for all-day entries.
// Now these are mixed with requests, which are technically also all-day
// entries.
// This code contains a number of workaround, but the problem should be
// resolved systematically!
// **********************************************************************

export interface Props {
  day?: PlannedDay
  showHeader?: boolean
}

export default function DayContent({
  day,
  showHeader,
}: Props): JSX.Element | null {
  if (day === null || day === undefined || day.isWorkflow()) {
    return null
  }
  return day.isAllDay() ? (
    <AllDayContent day={day}></AllDayContent>
  ) : (
    <RegularDayContent
      day={day}
      showHeader={showHeader === undefined || showHeader === true}
    ></RegularDayContent>
  )
}

function AllDayContent({ day }: { day: PlannedDay }): JSX.Element {
  const intl = useIntl()
  const seg = day.getVisiblePlannedEntries()[0]
  const endDateString = day.getEndDate(intl.locale)
  let endDateLabel = ""
  if (endDateString) {
    endDateLabel = ` ${intl.formatMessage({
      id: "time-until",
    })} ${endDateString}`
  }
  return (
    <div className="mobile-dashboard-entry-label mobile-holiday-label">
      {seg.getLabel()}
      {endDateLabel}
    </div>
  )
}

function RegularDayContent({
  day,
  showHeader,
}: {
  showHeader: boolean
  day: PlannedDay
}): JSX.Element {
  const intl = useIntl()
  const breaks = (e: Entry) =>
    e.getBreaks().map((b, idx) => (
      <span key={idx} className="mobile-dashboard-entry-break">
        {b.getTranslatedLabel((id, p) => intl.formatMessage({ id }, p))}
      </span>
    ))
  return (
    <div className="mobile-dashboard-day-entries">
      {showHeader ? (
        <div className="mobile-dashboard-day-hero-time-range">
          {day.getTimeRangeTitle(id => intl.formatMessage({ id }))}
        </div>
      ) : null}
      <div className="mobile-dashboard-day-department-name">
        {day.getDepartmentName()}
      </div>
      <div className="mobile-dashboard-day-entries">
        {day.getActivities().map((e, idx) => {
          return e.isEmptyDay() ? null : (
            <div className="mobile-dashboard-entry" key={idx}>
              <div className="mobile-dashboard-entry-times">
                <div className="mobile-dashboard-entry-start-time">
                  {e.getStartTime()}
                </div>
                <div className="mobile-dashboard-entry-end-time">
                  {e.getEndTime()}
                </div>
              </div>
              <div
                className="mobile-dashboard-entry-label"
                style={{
                  borderLeft: `3px solid ${e.getCSSColor()}`,
                }}
              >
                <span className="mobile-dashboard-entry-label">
                  {e.getEntryName()}
                </span>
                {e.getBreaks().length > 0 ? (
                  <>
                    <span className="mobile-dashboard-entry-breaks">
                      {breaks(e)}
                    </span>
                  </>
                ) : null}
                {e.getDepartmentName() ? (
                  <div className="mobile-dashboard-entry-one-off">
                    {e.getDepartmentName()}
                  </div>
                ) : e.getDepartmentId() ? (
                  intl.formatMessage({
                    id: "mobile.calendar.unknown-department",
                  })
                ) : null}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
