/**
 * "Wochenplan" report in GUI.
 */

import React, { Component } from "react"
import moment from "moment"
import type { ConnectedProps } from "react-redux"
import { connect } from "react-redux"
import type { IntlShape } from "react-intl"
import { injectIntl } from "react-intl"

import {
  VIEWPLANNER,
  EDITPLANNER,
  VIEWUSERREPORTS,
} from "../../../../shared/permissions"
import {
  getContext,
  getContextNode,
} from "../../../../shared/OUContext/controller"
import {
  hasOneOfPermissions,
  hasPermission,
} from "../../../../shared/permissionCheck/controller"
import { encodeURIComponentsForActions } from "../../../../shared/uriUtils"
import { getSelectedDepartmentsAndUsers } from "../../../../shared/ui/GroupedUserSelect/controller"
import type StoreState from "../../../../redux/state"
import { hasSelectedEmployees } from "../../../../shared/ui/GroupedUserSelect/utils"
import type { RangeDate } from "../../../../shared/ui/GroupedUserSelect/interfaces"
import { pad00 } from "../../../../shared/legacy/time-utils"

import { extractClientLanguage } from "../../../languagePicker/containers/LanguageSwitcher"
import { getLanguage } from "../../../languagePicker/controller"

import {
  saveReportRequest,
  resetReportId,
} from "../redux/actions/calendarReportsActions"
import { getReportId } from "../redux/selectors/calendarReportsSelectors"
import CalendarReportManager from "../components/CalendarReportsManager"
import type { SaveProps, State } from "../interfaces"

const mapStateToProps = (state: StoreState) => {
  const ouNode = getContextNode(state)
  const zoneName =
    ouNode && ouNode.getTimezoneName(undefined, { useNewest: true })
  const current_lang = getLanguage(state)
  const lang = extractClientLanguage(current_lang)
  return {
    oucontext: getContext(state),
    canView: permissionCheck(state),
    lang: lang.shortName as string,
    timezone: zoneName as string,
    selectedDepartmentsAndUsers: getSelectedDepartmentsAndUsers(state),
    reportId: getReportId(state),
  }
}

const mapDispatchToProps = (dispatch: (a: any) => void) => ({
  dispatchSave: (reportRequest: SaveProps) =>
    dispatch(saveReportRequest(reportRequest)),
  dispatchResetReportId: () => dispatch(resetReportId()),
})

interface OwnProps {
  intl: IntlShape
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type Props = ConnectedProps<typeof connector> & OwnProps

class CalendarReportsManagerContainer extends Component<Props, State> {
  private windowRef: Window | undefined
  constructor(props: Props) {
    super(props)
    /**
     * We used to have gui elements to toggle these states, but
     * for now we do this in the HTML report page directly and use
     * these state values as defaults for the link paramters.
     */
    const now = moment()
    const start = now.clone().startOf("isoWeek")
    const end = now.clone().endOf("isoWeek")
    this.state = {
      asXLSX: false,
      isoWeekFrom: now.isoWeek(),
      isoWeekYearFrom: now.isoWeekYear(),
      isoWeekUntil: now.isoWeek(),
      isoWeekYearUntil: now.isoWeekYear(),
      from: {
        day: start.date(),
        month: start.month(),
        year: start.year(),
      },
      until: {
        day: end.date(),
        month: end.month(),
        year: end.year(),
      },
      weekLength: "7",
      showBreaks: true,
      showAccounts: false,
      obfuscate: false,
      compact: true,
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (
      this.props.reportId !== undefined &&
      this.props.reportId !== prevProps.reportId
    ) {
      this.openReport(this.props.reportId, this.props.lang)
    }
  }

  openReport(reportId: string, lang: string) {
    let path = ""
    if (this.state.asXLSX) {
      path = "/rest/reports/weeklyScheduleXLSX?"
    } else {
      path = "/rest/reports/weeklySchedule?"
    }
    const url =
      path +
      encodeURIComponentsForActions({
        reportId,
        lang,
      })
    this.windowRef.location = url
    this.props.dispatchResetReportId()
  }

  render() {
    if (
      this.props.oucontext.orgunitId === undefined ||
      this.props.canView !== true
    ) {
      return null
    }

    const selectStart = (start: RangeDate) => {
      const m = moment(
        `${start.year}-${pad00(start.month + 1)}-${pad00(start.day)}`
      )
      this.setState({
        isoWeekFrom: m.isoWeek(),
        isoWeekYearFrom: m.isoWeekYear(),
        from: start,
      })
    }

    const selectEnd = (end: RangeDate) => {
      const m = moment(`${end.year}-${pad00(end.month + 1)}-${pad00(end.day)}`)
      this.setState({
        isoWeekUntil: m.isoWeek(),
        isoWeekYearUntil: m.isoWeekYear(),
        until: end,
      })
    }

    const onSubmit = (): void => {
      this.setState({ asXLSX: false }, () => saveReport())
    }

    const onSubmitXLSX = (): void => {
      this.setState({ asXLSX: true }, () => saveReport())
    }

    const saveReport = (): void => {
      this.windowRef = window.open()
      const reportRequest = {
        type: "calendarreport",
        departmentsAndUsers: this.props.selectedDepartmentsAndUsers,
        isoWeekFrom: this.state.isoWeekFrom,
        isoWeekYearFrom: this.state.isoWeekYearFrom,
        isoWeekUntil: this.state.isoWeekUntil,
        isoWeekYearUntil: this.state.isoWeekYearUntil,
        weekLength: this.state.weekLength,
        showBreaks: this.state.showBreaks,
        showAccounts: this.state.showAccounts,
        obfuscate: this.state.obfuscate,
        compact: this.state.compact,
        lang: this.props.lang,
        tz: this.props.timezone,
        asXLSX: this.state.asXLSX,
      }
      void this.props.dispatchSave(reportRequest)
    }

    const nothingSelected = !hasSelectedEmployees(
      this.props.selectedDepartmentsAndUsers
    )

    return (
      <CalendarReportManager
        onSubmit={onSubmit}
        onSubmitXLSX={onSubmitXLSX}
        translate={id => this.props.intl.formatMessage({ id })}
        selectStart={selectStart}
        selectEnd={selectEnd}
        nothingSelected={nothingSelected}
        from={this.state.from}
        until={this.state.until}
        intl={this.props.intl}
        lang={this.props.lang}
      />
    )
  }
}

const withDataStore = connector(injectIntl(CalendarReportsManagerContainer))
export default withDataStore

export const permissionCheck = (state: StoreState): boolean => {
  const hasPlannerPermission = hasOneOfPermissions(
    [VIEWPLANNER, EDITPLANNER],
    state
  )
  const hasReportPermission = hasPermission(VIEWUSERREPORTS, state)
  return hasPlannerPermission && hasReportPermission
}
