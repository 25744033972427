import * as React from "react"

export interface Props {
  label?: string
  value?: string
  currentValue?: string
  onClick?: (value: string) => void
  className?: string
  children?: React.ReactNode
}

export default class HorizontalNavbarItem extends React.Component<Props> {
  constructor(props: Props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  render(): JSX.Element {
    return (
      <div className={this.getClassName()} onClick={this.handleClick}>
        {this.props.label}
        {this.props.children}
      </div>
    )
  }

  private handleClick() {
    if (this.props.onClick !== undefined) {
      this.props.onClick(this.props.value)
    }
  }

  private getClassName() {
    let cls = this.props.className ? ` ${this.props.className}` : ""
    cls = this.isCurrent() ? ` mm-current${cls}` : cls
    cls = this.isClickable() ? ` mm-clickable${cls}` : cls
    const complete = `mm-horizontal-navbar-item${cls}`
    return complete
  }

  private isCurrent() {
    return this.props.value === this.props.currentValue
  }

  private isClickable() {
    return this.props.onClick !== undefined
  }
}
