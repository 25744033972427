import type { Action, ActionCreator, Dispatch } from "redux"
import type { ThunkAction } from "redux-thunk"
import types from "./actionTypes"
import {
  showGlobalMessage,
  convertBackendError,
} from "../../../../globalMessage/controller"
import { fetchREST } from "../../../../../shared/rest.js"
import type { SaveProps } from "../../interfaces"

const loadReportId: ActionCreator<Action> = (id: string) => ({
  type: types.LOADCALENDARREPORTID,
  id,
})

export const resetReportId: ActionCreator<Action> = () => ({
  type: types.LOADCALENDARREPORTID,
})

export const saveReportRequest: ActionCreator<
  ThunkAction<Promise<Action>, any, any, any>
> = (reportRequest: SaveProps) => {
  return async (dispatch: Dispatch, getState): Promise<Action> => {
    try {
      const res = await fetchREST(
        "/reports/weeklyScheduleConfig",
        { ...reportRequest },
        { state: getState(), method: "POST" }
      )
      const json = await res.json()
      if (json.error) {
        return showGlobalMessage(dispatch, convertBackendError(json))
      }
      return dispatch(loadReportId(json))
    } catch (err) {
      console.log(err)
    }
  }
}
