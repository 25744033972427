import type {
  BreakV3,
  EmployeeV3,
  EntryClassificationV3,
  EntryV3,
  LoggedEntryV3,
  OrganisationalUnitV3,
  PlannerV3,
  SimpleTime,
} from "../../commonInterfaces/PlannerV3"
import { findDepartment } from "../utils/findDepartment"

type DateTimeFormatOptions = Intl.DateTimeFormatOptions
const DAYINMILLISECONDS = 24 * 3600 * 1000
// TODO: i18n
class OrgUnitBase {
  constructor(protected plannerData?: PlannerV3) { }

  protected getOrgUnit(departmentId?: string) {
    return departmentId !== undefined
      ? this.findDepartment(
        departmentId,
        this.plannerData?.availableOrganisationalUnits
      )
      : undefined
  }

  private findDepartment(
    departmentId: string,
    roots?: OrganisationalUnitV3[]
  ): OrganisationalUnitV3 | undefined {
    return findDepartment(departmentId, roots ?? [])
  }
}

export default class PlannedDay extends OrgUnitBase {
  private orgUnit?: OrganisationalUnitV3

  constructor(
    private dateString: string,
    private entries: EntryV3[],
    private loggedEntries: LoggedEntryV3[],
    plannerData: PlannerV3,
    private employeeV3: EmployeeV3,
    private departmentId: string, // TODO: user ID + date??
    private locale: string = "de-DE"
  ) {
    super(plannerData)
    // this.entries = this.entries.filter(e => e.workflowAPI === undefined)
    this.orgUnit = this.getOrgUnit(departmentId)
  }

  public get loggedDayWasRejected(): boolean {
    return !!this.employeeV3.rejectedLoggedDays?.includes(this.dateString)
  }

  public get department(): OrganisationalUnitV3 | undefined {
    if (this.orgUnit === undefined) {
      console.log(
        "WARNING: departmentId has no associated department!",
        this.departmentId,
        this.orgUnit,
        this.employeeV3.name
      )
    }
    return this.orgUnit
  }

  public get userId(): string {
    return this.employee.id
  }

  public get employee(): EmployeeV3 {
    return this.employeeV3
  }

  public isLoggedDay(): boolean {
    return this.loggedEntries.length > 0
  }

  /**
   * Returns an **unsorted** Array of other people's PlannedDays
   */
  public getOtherPeople(): PlannedDay[] {
    const res: PlannedDay[] = []
    for (const departmentId of Object.keys(
      this.plannerData?.employeeCalendarsByOrganisationalUnit ?? {}
    )) {
      // each department
      const cals =
        this.plannerData?.employeeCalendarsByOrganisationalUnit[departmentId] ??
        []
      for (const cal of cals) {
        // each employee (V3)
        if (cal.id !== this.employee.id) {
          const entries = this.getEntriesForToday(cal.entries)
          const loggedEntries = this.getEntriesForToday<LoggedEntryV3>(
            cal.loggedEntries ?? []
          )
          if (entries.length > 0) {
            res.push(
              new PlannedDay(
                this.dateString,
                entries,
                loggedEntries,
                this.plannerData!,
                cal,
                departmentId,
                this.locale
              )
            )
          }
        }
      }
    }
    return res
  }

  public getHolidayName(): string | undefined {
    const o = this.getOrgUnit(this.departmentId)
    const r = o?.holidays?.find(h => h.date === this.dateString)?.name
    return r
  }
  public isHoliday(): boolean {
    return !!this.getHolidayName()
  }

  public getDate(locale: string): string {
    return this.getDateAsDisplayString(this.dateString, locale)
  }

  public getEndDate(locale: string = "de"): string | null {
    const d = this.entries[0]?.endDate
    if (d) {
      return this.getDateAsDisplayString(d, locale)
    } else {
      return null
    }
  }

  public getDateTitle(): string {
    const { lWeekdayLabel } = this.getRelativeDay()
    return lWeekdayLabel
  }

  public getWeekday(): string {
    return getWeekday(this.getJSDate(), this.locale)
  }

  public getTimeRangeTitle(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    translate: (msgId: string) => string = a => a
  ): string {
    if (this.isAllDay() && !this.isWorkflow()) {
      return translate("abwesend")
    }
    const entries = this.getVisiblePlannedEntries()
    if (entries.length === 0) {
      return translate("keine Einträge")
    }
    const f = entries[0]
    const l = entries[entries.length - 1]
    return `${f.getStartTime()} - ${l.getEndTime()} ${translate("o'clock")}`
  }

  public isWorkflow(): boolean {
    // TODO: There might be conflicts here if a workflow and a vacation coexist
    return this.entries.length === 1 && this.entries[0].workflowAPI !== undefined
  }

  public getActivities<T extends EntryV3 | LoggedEntryV3 = EntryV3>(): Entry[] {
    const cmp = (a: Entry, b: Entry) => a.cmp(b)
    const entries: T[] =
      this.loggedEntries?.length > 0
        ? (this.loggedEntries as T[])
        : (this.entries as T[])
    const res = entries
      .filter(e => !e.isEmptyDay && !(e as EntryV3).workflowAPI)
      .map(e => new Entry<T>(e, this.plannerData))
      .sort(cmp)
    return res
  }

  public getEntries<T extends EntryV3 | LoggedEntryV3 = EntryV3>(
    includeEmpty: boolean = true
  ): Entry[] {
    const cmp = (a: Entry, b: Entry) => a.cmp(b)
    const entries: T[] =
      this.loggedEntries?.length > 0
        ? (this.loggedEntries as T[])
        : (this.entries as T[])
    const res = entries
      .filter(e => (includeEmpty === false ? !e.isEmptyDay : true))
      .map(e => new Entry<T>(e, this.plannerData))
      .sort(cmp)
    return res
  }

  public getVisiblePlannedEntries<T extends EntryV3 | LoggedEntryV3 = EntryV3>(): Entry[] {
    return this.getEntries<T>(false).filter(e => e.getEntryV3().workflowAPI === undefined)
  }

  public getVisiblePlannedEntriesV3(): EntryV3[] {
    const entries = this.entries.filter(
      e => e.workflowAPI === undefined
        && (
          !e.isAllDay
          || (
            e.isAllDay
            && (
              !e.isUnchangedFromAutoplan
              || !this.loggedEntries.find(e2 => e2.startDate === e.startDate)
            )
          )
        )
    )
    return entries
  }


  public isAllDay(): boolean {
    const entries = this.getVisiblePlannedEntriesV3()
    return entries.length === 1
      && !!entries[0].isAllDay
    // !this.entries[0].endTime
  }

  public getDepartmentName(): string | undefined {
    return this.getOrgUnit(this.departmentId)?.fullname
  }

  public isToday(): boolean {
    const now = new Date()
    const d = this.getJSDate()
    return (
      d.getFullYear() === now.getFullYear() &&
      d.getMonth() === now.getMonth() &&
      d.getDate() === now.getDate()
    )
  }
  public getJSDate(dateString?: string): Date {
    const [y, m, d] = (dateString ?? this.dateString)
      .split("-")
      .map(n => parseInt(n, 10))
    const date = new Date(y, m - 1, d)
    return date
  }

  public getJSEndDate(): Date | null {
    const d = this.entries[0]?.endDate
    if (d) {
      return this.getJSDate(d)
    } else {
      return null
    }
  }

  private getEntriesForToday<T extends EntryV3 | LoggedEntryV3>(
    entries: T[]
  ): T[] {
    return entries.filter(
      // TODO: This should be adapted
      e =>
        e.startDate === this.dateString ||
        (
          e.isAllDay
          && e.endDate !== undefined
          && e.endDate !== null
          && e.startDate <= this.dateString
          && e.endDate >= this.dateString
        )
    )
  }

  private getDateAsDisplayString(
    dateJSONString: string,
    locale: string = "de"
  ) {
    const [y, m, d] = dateJSONString.split("-").map(n => parseInt(n, 10))
    const date = new Date(y, m - 1, d)
    const options: DateTimeFormatOptions = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    }
    const { isRelativeDay } = this.getRelativeDay()
    if (isRelativeDay) {
      options.weekday = "long"
    }
    return date.toLocaleDateString(locale ?? this.locale, options)
  }

  private getRelativeDay(): RelativeDayResult {
    const date = this.getJSDate()
    const now = new Date()
    const today = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    )
    const diffDays = Math.round(
      (date.getTime() - today.getTime()) / DAYINMILLISECONDS
    )
    switch (diffDays) {
      case -1:
        return {
          lWeekdayLabel: "Gestern",
          isRelativeDay: true,
        }
      case 0:
        return {
          lWeekdayLabel: "Heute",
          isRelativeDay: true,
        }
      case 1:
        return {
          lWeekdayLabel: "Morgen",
          isRelativeDay: true,
        }
      default:
        return {
          lWeekdayLabel: getWeekday(date),
          isRelativeDay: false,
        }
    }
  }
}

interface RelativeDayResult {
  lWeekdayLabel: string

  /**
   * The result is day with a valid relative label
   * (e.g. "today" or "yesterday")
   */
  isRelativeDay: boolean
}

// TODO: Move this outside, re-establish proper hierarchy (CommonBase)
export class Entry<
  T extends EntryV3 | LoggedEntryV3 = EntryV3
> extends OrgUnitBase {
  constructor(protected entry: T, plannerData?: PlannerV3) {
    super(plannerData)
  }

  public getEntryV3(): T {
    return this.entry
  }

  public getPlannerData(): PlannerV3 | undefined {
    return this.plannerData
  }

  public isEmptyDay(): boolean {
    return !!this.entry.isEmptyDay
  }

  public clone(): Entry {
    return new Entry({ ...this.entry /* shallow copy! */ }, this.plannerData)
  }

  public getEntryName(): string {
    const r = this.entry.name
    return r ?? ""
  }

  public getDepartmentName(): string | undefined {
    let r: string | undefined
    const ou = this.getOrgUnit(this.entry.organisationalUnitIdForThisEntry)
    if (ou !== undefined) {
      r = ou.fullname
    }
    return r
  }

  public getLabel(): string {
    let r = this.entry.name
    const ou = this.getOrgUnit(this.entry.organisationalUnitIdForThisEntry)
    if (ou !== undefined) {
      r += " " + ou.fullname
    }
    return r ?? ""
  }

  public getClassification(): EntryClassificationV3 | undefined {
    return this.entry.entryClassification
  }

  public getDepartmentId(): string | undefined {
    return this.entry.organisationalUnitIdForThisEntry
  }

  public getCSSColor(): string {
    const c = this.entry.entryClassification?.color
    if (c && c.r && c.g && c.b) {
      return `#${c.r.toString(16)}${c.g.toString(16)}${c.b.toString(16)}`
    } else {
      return "#888888"
    }
  }

  public getStartDate(): string {
    return this.entry.startDate
  }

  public getStartTime(): string | undefined {
    const s = getTime(this.entry.startTime, true)
    return s
  }

  public getEndTime(): undefined | string {
    return this.entry.endTime ? getTime(this.entry.endTime, true) : undefined
  }

  public getApproximateLengthInHours(): number {
    if (this.entry.startDate === undefined) {
      return 0
    }
    const [y1, m1, d1] = this.entry.startDate
      .split("-")
      .map(i => parseInt(i, 10))
    const [y2, m2] = [y1, m1]
    let d2 = d1
    const startStr = this.getStartTime() ?? "00:00"
    const endStr = this.getEndTime() ?? "24:00"
    if (endStr < startStr) {
      d2 += 1
    }
    const [h1, min1] = startStr.split(":").map(i => parseInt(i, 10))
    const [h2, min2] = endStr.split(":").map(i => parseInt(i, 10))
    const date1 = new Date(y1, m1 - 1, d1, h1, min1)
    const date2 = new Date(y2, m2 - 1, d2, h2, min2)
    return (date2.getTime() - date1.getTime()) / (3600 * 1000)
  }

  public mightBeAllDayEntry(): boolean {
    return (
      !this.entry.endTime ||
      this.entry.isAllDay ||
      (this.entry.entryClassification !== undefined &&
        !this.entry.entryClassification.isActivity)
    )
  }

  public getBreaks(): Break[] {
    return (this.entry.breaks ?? []).map(b => new Break(b))
  }

  public getMessage(): string | undefined {
    return (this.entry as LoggedEntryV3).message
  }

  public cmp(other: Entry): number {
    if (this.mightBeAllDayEntry()) {
      return this.getStartDate() < other.getStartDate()
        ? -1
        : this.getStartDate() > other.getStartDate()
          ? 1
          : 0
    } else {
      return this.getStartTime()! < other.getStartTime()!
        ? -1
        : this.getStartTime()! > other.getStartTime()!
          ? 1
          : 0
    }
  }
}

export class Break {
  constructor(private brk: BreakV3) { }

  public getBreakV3(): BreakV3 {
    return this.brk
  }

  public getLabel(): string {
    return `${this.getStartTime()}-${this.getEndTime()} (${this.getDurationInMinutes()} Min)`
  }

  public getTranslatedLabel(
    translate: (
      id: string,
      params: {
        startTime: string
        endTime: string
        durationInMinutes: string
      }
    ) => string
  ): string {
    return translate("mobile-break-label", {
      startTime: this.getStartTime() ?? "",
      endTime: this.getEndTime() ?? "",
      durationInMinutes: `${this.getDurationInMinutes()}`,
    })
  }

  public getStartTime(): string | undefined {
    return getTime(this.brk.startTime)
  }

  public getEndTime(): string | undefined {
    return getTime(this.brk.endTime)
  }

  public getDurationInMinutes(): number {
    return this.brk.durationInMinutes
  }
}

function getTime(t?: SimpleTime, allowUndefinedTime: boolean = false) {
  if (t === undefined) {
    if (allowUndefinedTime) {
      return undefined
    } else {
      return "00:00"
    }
  }
  return `${`${t.hour}`.padStart(2, "0")}:${`${t.minute}`.padStart(2, "0")}`
}

export function getWeekday(d: Date, locale: string = "de-DE"): string {
  return d.toLocaleDateString(locale, { weekday: "long" })
}
