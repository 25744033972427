/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * The React/redux renderer plugin for Core Tables
 *
 * Note: Invisible columns are not currently supported (and it's hard to see
 * why we would need them in this environment)
 */

import React from "react"
import { Table as AntdTable, Button } from "antd"
import type { CoreTableColumn, DataStore, Row } from "../../view-model-table"
import { ASC, BOOL, DESC } from "../../view-model-table"
import { ConfigProvider } from "antd"
import "./css/Renderer.css"
import { getAntdLocale } from "../../antdLocales"

import moment from "moment"

export default class Renderer {
  public name: string
  private columns: any[]
  private dataStore: any
  private addRowAttrs: any
  private tableOptions: {
    rowsPerPage?: number
    lang?: string
    scroll?: {
      x?: string | number | boolean
      y?: string | number
    }
  }
  private renderCellContents: any

  /**
   * @param handleClickRow A function that is invoked every time the row is
   * clicked; this function is passed the raw row
   * instance (an object with keys to all columns,
   * and whatever other information you put in there)
   * and the numeric index inside the current table view
   * @param renderCellContents A function that is invoked every time a cell is
   * rendered; this is used to modify the contents of
   * the cell, and is passed three arguments:
   * the cell's CoreTableColumn instance, its content,
   * and the whole row. **Note**: This can lead to
   * serious performance problems unless handled
   * with great care! Note also that *if* you provide
   * this function, you must return the contents by
   * default (don't return undefined or null!)
   * @param addRowAttrs (optional) Adds attributes to the (Antd) `Table` row;
   * please note that if you *do* supply this parameter, the
   * function must return an object that may contain all
   * attributes processed by onRow, and className.
   * Invoked as `addRowAttrs(row, idx)`
   * @param tableOptions (optional) A optional object of table settings.
   * Currently valid are: { rowsPerPage: integer, lang: string,
   * scoll: {x?: string | number | true, y?: string | number} }
   */
  constructor(
    handleClickRow: ClickRowFun,
    renderCellContents: RenderCellContentsFun,
    addRowAttrs = () => ({}),
    tableOptions = {}
  ) {
    this.addRowAttrs = addRowAttrs
    this.attachRender(renderCellContents, handleClickRow)
    this.tableOptions = tableOptions
  }

  attachRender(
    renderCellContents: RenderCellContentsFun,
    handleClickRow: ClickRowFun
  ): void {
    const renderCellContentsInner =
      renderCellContents === undefined
        ? (___col, contents) => contents
        : renderCellContents
    const wrappedInnerClickHandler = (col, contents, row) => {
      if (col.getType() === BOOL) {
        return renderCellContentsInner(col, contents, row)
      } else {
        return col.isClickable() ? (
          <div className="clickableColumn" onClick={() => handleClickRow(row)}>
            {renderCellContentsInner(col, contents, row)}
          </div>
        ) : (
          renderCellContentsInner(col, contents, row)
        )
      }
    }
    const renderCellContentsOuter =
      handleClickRow === undefined
        ? renderCellContentsInner
        : wrappedInnerClickHandler
    this.renderCellContents = renderCellContentsOuter
  }

  /**
   * ALL API REQUIRED by CoreTable
   */

  setName(name: string): void {
    this.name = name
  }

  attachColumns(columns: CoreTableColumn[]): void {
    this.columns = columns
  }

  attachDataStore(dataStore: DataStore): void {
    this.dataStore = dataStore
  }

  render(Table: any = FilteredTable): JSX.Element {
    return (
      <Table
        dataStore={this.dataStore}
        columns={this.columns}
        addRowAttrs={this.addRowAttrs}
        tableOptions={this.tableOptions}
        renderCellContents={this.renderCellContents}
        scroll={this.tableOptions.scroll}
      ></Table>
    )
  }
}

interface Props {
  dataStore: any
  columns: any[]
  addRowAttrs: any
  tableOptions: any
  renderCellContents: any
  lang: "de"
}

interface State {
  filteredValues: any
  pageSize: number
}

class FilteredTable extends React.Component<Props, State> {
  private filterRefs: React.RefObject<HTMLInputElement>[]
  constructor(props) {
    super(props)
    this.state = {
      filteredValues: {},
      pageSize: Number.isInteger(this.props.tableOptions.rowsPerPage)
        ? this.props.tableOptions.rowsPerPage
        : 10,
    }
    this.filterRefs = []
    const maxColumns = 100
    for (let i = 0; i < maxColumns; i++) {
      this.filterRefs[i] = React.createRef()
    }
  }

  render() {
    // TODO: Extra container className
    const headerRow = this.getHeaderRow()
    const rows = this.getRows()
    // TODO: Fix rawRows? (Add filter there too?)
    const rawRows = this.props.dataStore.getAllRows() ?? []
    const onChangeHandler = (pagination: { pageSize: number }) => {
      this.setState({ pageSize: pagination.pageSize })
    }
    const scroll = this.props.tableOptions.scroll ?? undefined
    return (
      <>
        {this.hasAnyFilterSet() ? (
          <div className="filtered-table-warning">
            <div>
              GEFILTERT
              <span className="filtered-table-warning-spacer" />
              {this.getFilterDescription()}
              <span className="filtered-table-warning-spacer" />
              <a
                onClick={() =>
                  this.setState({
                    filteredValues: {},
                  })
                }
              >
                Alle Filter leeren
              </a>
            </div>
          </div>
        ) : null}
        <ConfigProvider locale={getAntdLocale(this.props.lang ?? "de")}>
          <AntdTable
            bordered
            scroll={scroll}
            dataSource={rows}
            columns={headerRow}
            pagination={{
              pageSize: this.state.pageSize,
              hideOnSinglePage: true,
            }}
            onRow={(___row, idx) => {
              const rawRow = rawRows[idx]
              if (rawRow) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { className, ...attrs } = this.props.addRowAttrs(
                  rawRow,
                  idx
                ) // eslint-disable-line
                return attrs
              }
              return {}
            }}
            rowClassName={(___row, idx) => {
              const rawRow = rawRows[idx]
              if (rawRow && this.props.addRowAttrs) {
                const attrs = this.props.addRowAttrs(rawRow, idx)
                return attrs.className ? attrs.className : ""
              }
              return ""
            }}
            onChange={onChangeHandler}
          />
        </ConfigProvider>
      </>
    )
  }

  private getFilterDescription() {
    const r = []
    for (const c of this.props.columns) {
      const v = this.state.filteredValues[c.getName()]
      if (v) {
        r.push(`${c.getTitle()}: ${v}`)
      }
    }
    return (
      <>
        {r.map((d, idx) => (
          <React.Fragment key={idx}>
            {d}
            <span className="filtered-table-warning-spacer" />
          </React.Fragment>
        ))}
      </>
    )
  }

  private hasAnyFilterSet() {
    return Object.values(this.state.filteredValues).filter(v => !!v).length > 0
  }

  private getHeaderRow() {
    // TODO: filter
    const { sortColumnName, sortDirection } = this.props.dataStore.getSort()
    const rows = this.props.dataStore.getRows(
      this.props.columns
      // this.state.filteredValues
    )
    return this.props.columns.map((c, idx) => {
      const showFilter = c.isVisible() && (c.isString() || c.isDate())
      const col: any = {
        key: idx,
        title: c.getTitle(),
        dataIndex: c.getName(),
        sorter: true,
        filteredValue: showFilter // just to get the icon to highlight
          ? this.state.filteredValues[c.getName()] !== undefined &&
            this.state.filteredValues[c.getName()] !== ""
            ? [this.state.filteredValues[c.getName()]]
            : []
          : undefined,
        filtered: showFilter
          ? this.state.filteredValues[c.getName()] !== undefined &&
            this.state.filteredValues[c.getName()] !== ""
          : undefined,
        onFilter: showFilter
          ? (value, record) => {
              const text = getTextFrom(
                this.renderCellContentsWrapper(
                  c,
                  record[c.getName()],
                  rows[record.key]
                ) ?? ""
              ).toLowerCase()
              const res = text.includes(value.toLowerCase())
              return res
            }
          : undefined,
        filterIcon: showFilter ? undefined : <></>,
        filterDropdown: props => {
          if (
            props.visible &&
            this.filterRefs[idx].current !== document.activeElement
          ) {
            window.requestAnimationFrame(() => {
              this.filterRefs[idx].current?.focus()
            })
          }
          const search = (v, close = false) => {
            this.setState(
              {
                filteredValues: {
                  ...this.state.filteredValues,
                  [c.getName()]: v,
                },
              },
              () => (close ? props.confirm() : undefined)
            )
          }
          return (
            <div
              className="table-filter"
              onClick={e => e.stopPropagation()}
              style={{
                display: showFilter ? "block" : "none",
              }}
            >
              <input
                ref={this.filterRefs[idx]}
                autoFocus={true}
                onChange={e => search(e.target.value)}
                value={this.state.filteredValues[c.getName()] ?? ""}
                onBlur={() => props.confirm({ close: false })}
                onKeyDown={e => {
                  switch (e.key) {
                    case "Enter":
                    case "Esc":
                    case "Escape":
                      props.confirm()
                      break
                    default:
                      break
                  }
                }}
              />
              {
                /* (this.state.filteredValues[c.getName()] ?? "") !== "" ? ( */
                <Button
                  size={"small"}
                  style={{ padding: 0, margin: 0, marginLeft: 10 }}
                  type="link"
                  onClick={e => {
                    const inp = (e.target as HTMLElement)
                      .closest("div.table-filter")
                      .querySelector("input")
                    this.setState(
                      {
                        filteredValues: {
                          ...this.state.filteredValues,
                          [c.getName()]: "",
                        },
                      },
                      () => {
                        inp.value = ""
                        search("")
                        props.confirm()
                      }
                    )
                  }}
                >
                  Leeren
                </Button>
                /* ) : null */
              }
            </div>
          )
        },
        onHeaderCell: () => ({
          onClick: () => {
            if (c.isSortable()) {
              this.props.dataStore.sort(
                c.getName(),
                sortDirection === DESC ? ASC : DESC
              )
            }
          },
        }),
        sortOrder:
          c.isSortable() && sortColumnName === c.getName()
            ? sortDirection === DESC
              ? "descend"
              : "ascend"
            : false,
      }
      if (this.props.renderCellContents !== undefined) {
        col.render = (contents, record) => {
          return this.renderCellContentsWrapper(c, contents, rows[record.key])
        }
      }
      return col
    })
  }

  private renderCellContentsWrapper(col, contents, row) {
    let mappedContents = this.props.renderCellContents(col, contents, row)
    if (mappedContents === contents) {
      // postprocessing opportunities...
      if (col.isDate()) {
        mappedContents = this.renderDate(contents)
      }
    }
    return mappedContents
  }

  private renderDate(contents) {
    // TODO: Duplicated in connected-form, needs common util!
    return moment(new Date(contents), "YYYY-MM-DD")
      .toDate()
      .toLocaleDateString()
  }

  private getRows() {
    // TODO: Table.row.warning, onClick -> for ID
    // TODO: collapsing/non-collapsing  -> for column
    const rows = this.props.dataStore.getRows(
      this.props.columns,
      this.state.filteredValues
    )
    return rows.map((rowIn, rowidx) =>
      this.props.columns.reduce(
        (row, col) => {
          row[col.getName()] = rowIn[col.getName()]
          return row
        },
        { key: rowidx }
      )
    )
  }
}

function getTextFrom(reactNode) {
  if (typeof reactNode === "string" || typeof reactNode === "number") {
    return `${reactNode}`
  } else if (reactNode === undefined || reactNode === null) {
    return ""
  }
  const c = reactNode?.props?.children
  if (c instanceof Array) {
    return c.map(getTextFrom).join(" ")
  }
  return getTextFrom(c)
}

type RenderCellContentsFun = (
  cell: CoreTableColumn,
  content: any,
  row: Row
) => JSX.Element
type ClickRowFun = (row: Record<string, any>, idx?: number) => void
