import * as React from "react"
import DayContent from "../utils/DayContent"
import type { Day } from "./MobileHorizCalendar"
import "./less/mobileCalendarDay.less"
import type { IntlShape } from "react-intl"
import { FormattedMessage, injectIntl } from "react-intl"
import { Divider } from "antd"
import type PlannedDay from "../configuration/PlannedDay"
import { CaretDownOutlined, CaretLeftOutlined } from "@ant-design/icons"
import type PlannerData from "../configuration/PlannerData"
import type { OrganisationalUnitV3 } from "../../commonInterfaces/PlannerV3"

export interface Props {
  intl: IntlShape
  day: Day
  plannerData?: PlannerData
}

interface State {
  otherToggledOpen?: boolean
  openedDepartments: { [id: string]: boolean }
  openedEmployees: { [id: string]: boolean }
}

class MobileCalendarDayOtherPeople extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      openedDepartments: {},
      openedEmployees: {},
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (prevProps.day !== this.props.day) {
      this.setState({
        otherToggledOpen: false,
        openedDepartments: {},
        openedEmployees: {},
      })
    }
  }

  render(): JSX.Element | null {
    const otherPeopleByDept = this.getOtherPeopleByDept()
    if (otherPeopleByDept?.length > 0) {
      return (
        <div className="mobile-calendar-other-people">
          <Divider className="other-people-divider" plain>
            <span
              style={{ cursor: "pointer" }}
              onClick={() =>
                this.setState({
                  otherToggledOpen: !this.state.otherToggledOpen,
                })
              }
            >
              <FormattedMessage id="other-planned-employees"></FormattedMessage>
              &nbsp;
              {this.state.otherToggledOpen ? (
                <CaretDownOutlined
                  onClick={() => this.setState({ otherToggledOpen: false })}
                ></CaretDownOutlined>
              ) : (
                <CaretLeftOutlined
                  onClick={() => this.setState({ otherToggledOpen: true })}
                ></CaretLeftOutlined>
              )}
            </span>
          </Divider>
          {this.state.otherToggledOpen
            ? this.getOtherPeopleContents(otherPeopleByDept)
            : null}
        </div>
      )
    } else {
      return null
    }
  }

  private getOtherPeopleContents(otherPeopleByDept: PlannedDay[][]) {
    return otherPeopleByDept.map((otherPeople, idx1) => {
      const dept = otherPeople[0]?.department
      const opened = this.state.openedDepartments[idx1]
      // DEFAULT to open:
      // const opened = openedRaw === true || openedRaw === undefined
      return (
        <div className="other-people-dept" key={idx1}>
          <div
            onClick={() => {
              const openedDepartments = { ...this.state.openedDepartments }
              openedDepartments[idx1] = !openedDepartments[idx1]
              this.setState({ openedDepartments })
            }}
            className="other-people-dept-name"
          >
            <span className="dept-name">{dept?.fullname ?? ""}</span>
            &nbsp;
            <div className="other-people-toggle-dept">
              {opened ? (
                <CaretDownOutlined></CaretDownOutlined>
              ) : (
                <CaretLeftOutlined></CaretLeftOutlined>
              )}
            </div>
          </div>
          {opened ? this.getOtherPeopleDeptContents(otherPeople) : null}
        </div>
      )
    })
  }

  private getOtherPeopleDeptContents(otherPeople: PlannedDay[]) {
    return (
      <div className="other-people-dept-employees">
        {otherPeople.map((plannedDay, idx2) => {
          const openedRaw = this.state.openedEmployees[idx2]
          // DEFAULT to open:
          const opened = openedRaw === true || openedRaw === undefined
          return !plannedDay.isAllDay() &&
            plannedDay.getEntries(false).length > 0 ? (
            <div key={idx2} className="other-people-employee">
              <div
                onClick={() => {
                  const openedEmployees = { ...this.state.openedEmployees }
                  const currentState =
                    openedEmployees[idx2] === undefined ||
                    openedEmployees[idx2] === true
                  openedEmployees[idx2] = !currentState
                  this.setState({ openedEmployees })
                }}
                className="other-people-employee-name"
              >
                {plannedDay.employee.name}
                &nbsp;
                <div className="other-people-toggle-employee">
                  {opened ? (
                    <CaretDownOutlined></CaretDownOutlined>
                  ) : (
                    <CaretLeftOutlined></CaretLeftOutlined>
                  )}
                </div>
              </div>
              {opened ? (
                <DayContent
                  key={idx2}
                  day={plannedDay}
                  showHeader={false}
                ></DayContent>
              ) : null}
            </div>
          ) : null
        })}
      </div>
    )
  }

  private flatSortedDepartments(
    depts?: OrganisationalUnitV3[]
  ): OrganisationalUnitV3[] {
    depts = depts ?? this.props.plannerData?.getAvailableDepartments()
    if (depts?.length === 0) {
      return []
    }
    return (depts ?? []).reduce(
      (acc: OrganisationalUnitV3[], d: OrganisationalUnitV3) => [
        ...acc,
        d,
        ...this.flatSortedDepartments(d.children),
      ],
      []
    )
  }

  private getOtherPeopleByDept() {
    const plannedDay = this.props.day?.plannedDay
    const depts = this.flatSortedDepartments()
    if (plannedDay && depts && depts.length > 0) {
      const deptPositions: { [deptId: string]: number } = depts.reduce(
        (agg: Record<string, number>, dept, idx) => {
          agg[dept.id] = idx
          return agg
        },
        {}
      )
      const otherPeople = plannedDay
        .getOtherPeople()
        .filter(pd => pd.getEntries(false).length > 0)
      const byDept: { [k: string]: PlannedDay[] } = {}
      for (const pd of otherPeople) {
        if (pd.department !== undefined) {
          const deptId: string = pd.department.id
          if (byDept[deptId] === undefined) {
            byDept[deptId] = []
          }
          byDept[deptId].push(pd)
        }
      }
      return [...Object.keys(byDept)]
        .sort((a, b) =>
          deptPositions[a] < deptPositions[b]
            ? -1
            : deptPositions[a] > deptPositions[b]
            ? 1
            : 0
        )
        .map(deptName =>
          byDept[deptName].sort((a, b) =>
            a.employee.lastname < b.employee.lastname
              ? -1
              : a.employee.lastname > b.employee.lastname
              ? 1
              : a.employee.firstname < b.employee.firstname
              ? -1
              : a.employee.firstname > b.employee.firstname
              ? 1
              : 0
          )
        )
    } else {
      return []
    }
  }
}

const MobileCalendarDayOtherPeopleWithIntl = injectIntl(
  MobileCalendarDayOtherPeople
)

export default MobileCalendarDayOtherPeopleWithIntl
