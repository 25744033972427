import React from "react"
import { Space, Button } from "antd"
import type { IntlShape } from "react-intl"

import { AntdLocaleWrapper } from "../../../../shared/ui/AntdLocaleWrapper"
import type { RangeDate } from "../../../../shared/ui/GroupedUserSelect/interfaces"
import GroupedUserSelect from "../../../../shared/ui/GroupedUserSelect/GroupedUserSelect"
import RangePickerWithType from "../../../../shared/ui/RangePickerWithType"

// XXX: CSS?
const LABELSTYLE = {
  fontWeight: 700,
  fontSize: "80%",
  display: "block",
}

interface Props {
  nothingSelected: boolean
  from: RangeDate
  until: RangeDate
  intl: IntlShape
  lang: string
  selectStart: (start: RangeDate) => void
  selectEnd: (end: RangeDate) => void
  translate: (msg: string) => string
  onSubmit: () => void
  onSubmitXLSX: () => void
}

const CalendarReportsManager = ({
  nothingSelected,
  from,
  until,
  intl,
  lang,
  selectStart,
  selectEnd,
  translate,
  onSubmit,
  onSubmitXLSX,
}: Props): JSX.Element => {
  return (
    <div>
      <h1>{translate("calendarReports.title")}</h1>
      <Space className="report-gen-btn">
        <Button type="primary" onClick={onSubmit} disabled={nothingSelected}>
          {translate("calendarReports.generateReport")}
        </Button>
        {false && (
          <Button
            type="primary"
            onClick={onSubmitXLSX}
            disabled={nothingSelected}
          >
            {translate("calendarReports.generateXLSXReport")}
          </Button>
        )}
      </Space>
      <Space direction="vertical" size="middle" style={{ width: "100%" }}>
        <div>
          <label style={LABELSTYLE}>
            {translate("calendarReports.calendarWeek")}
          </label>
          <AntdLocaleWrapper>
            <RangePickerWithType
              intl={intl}
              lang={lang}
              defaultType="week"
              allowTypeSelect={false}
              maximumRangeInMonths={12}
              fromChange={selectStart}
              untilChange={selectEnd}
            />
          </AntdLocaleWrapper>
        </div>
        <GroupedUserSelect from={from} until={until} includeOneOff={true} />
      </Space>
    </div>
  )
}

export default CalendarReportsManager
