import { createSelector } from "reselect"
import { valueAt } from "../../../../shared/walkObjectHierarchy"

/**
 * @returns a function whose single parameter is a permission name; this
 *          function returns an Array of organisational units where the
 *          current user has that permission. Note that the OUs only
 *          have a minimal representation: { id, name, abbrv }
 */
export const getOrgUnitsWithPermission = createSelector(
  state => valueAt(state, ["permissionCheck", "invertedPermissions"]),
  invertedPermissions => permission => {
    return invertedPermissions && invertedPermissions[permission]
      ? invertedPermissions[permission]
      : undefined
  }
)

export const getPermissionsState = createSelector(
  state => valueAt(state, ["permissionCheck", "permissions", "context"]),
  permissions => (permissions !== undefined ? permissions : [])
)

export const getParentPermissionsState = createSelector(
  state => valueAt(state, ["permissionCheck", "permissions", "parent"]),
  permissions => (permissions !== undefined ? permissions : [])
)

export const getClientPermissionsState = createSelector(
  state => valueAt(state, ["permissionCheck", "permissions", "client"]),
  permissions => (permissions !== undefined ? permissions : [])
)
