import * as React from "react"
import type { IntlShape } from "react-intl"
import { injectIntl } from "react-intl"
import type PlannedDay from "../configuration/PlannedDay"
import DayContent from "../utils/DayContent"

export interface Props {
  intl: IntlShape
  day: PlannedDay
  selectDay: () => void
  selected: boolean
}

class MobileDashboardDay extends React.Component<Props> {
  render(): JSX.Element {
    const d = this.props.day
    return (
      <div
        className={this.getDashboardDayClass()}
        key={d.getDate(this.props.intl.locale)}
      >
        <div
          className="mobile-dashboard-day-header"
          onClick={this.props.selectDay}
        >
          <div className="mobile-dashboard-day-header-date-title">
            {this.props.intl.formatMessage({ id: d.getDateTitle() })}
          </div>
          <div className="mobile-dashboard-day-header-time-range">
            {this.props.selected
              ? d.getDate(this.props.intl.locale)
              : d.getTimeRangeTitle((id: string) =>
                  this.props.intl.formatMessage({ id })
                )}
          </div>
        </div>
        {d.isHoliday() ? (
          <div className="mobile-dashboard-holiday-banner">
            {d.getHolidayName()}
          </div>
        ) : null}
        <div className="mobile-dashboard-day-content">
          <DayContent day={this.props.day}></DayContent>
        </div>
      </div>
    )
  }

  private getDashboardDayClass() {
    const cls = ["mobile-dashboard-day"]
    if (this.props.selected) {
      cls.push("mobile-dashboard-day-selected")
    }
    return cls.join(" ")
  }
}

const MobileDashboardDayWithIntl = injectIntl(MobileDashboardDay)
export default MobileDashboardDayWithIntl
