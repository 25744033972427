// TODO: Needs Hierarchy and OUContext as props to avoid redux

import React, { Component } from "react"
import type { ConnectedProps } from "react-redux"
import { connect } from "react-redux"
import type { IntlShape } from "react-intl"
import { injectIntl } from "react-intl"
import { Checkbox, Space, Tree } from "antd"

import type StoreState from "../../../redux/state"

import {
  getContext,
  getContextNode,
  getHierarchy,
} from "../../OUContext/controller"
import type Hierarchy from "../../models/Hierarchy"
import { isEqual } from "../../walkObjectHierarchy"

import {
  loadReportDepartmentsAndUsers,
  loadReportOneOffDepartmentsAndUsers,
  setSelectedDepartmentsAndUsers,
} from "./redux/groupedUserSelectActions"
import {
  getAllowedDepartmentsAndUsers,
  getSelectedDepartmentsAndUsers,
} from "./redux/groupedUserSelectSelectors"
import type { RangeDate, ReportDepartmentsAndUsers } from "./interfaces"
import {
  atLeastOneChildHasHalfSelection,
  atLeastOneOtherHasHalfSelection,
  buildCheckboxGroups,
  createTreeData,
  isNotFullySelected,
} from "./checkboxUtils"

import "./groupeduserselect.css"

const mapStateToProps = (state: StoreState) => {
  const ouNode = getContextNode(state)
  const zoneName =
    ouNode && ouNode.getTimezoneName(undefined, { useNewest: true })
  return {
    hierarchy: getHierarchy(state),
    step1Updated: state?.groupedUserSelect?.updated,
    oucontext: getContext(state),
    departments: getAllowedDepartmentsAndUsers(state),
    tz: zoneName as string,
    reportDepartmentsAndUsers: getSelectedDepartmentsAndUsers(state),
  }
}

const mapDispatchToProps = (dispatch: (a: any) => void) => ({
  dispatchSet: (departmentsAndUsers: ReportDepartmentsAndUsers) =>
    dispatch(setSelectedDepartmentsAndUsers(departmentsAndUsers)),
  dispatchLoad: (
    includeHiddenEmployees: boolean,
    tz: string,
    from?: RangeDate,
    until?: RangeDate
  ) =>
    dispatch(
      loadReportDepartmentsAndUsers(includeHiddenEmployees, tz, from, until)
    ),
  dispatchOneOffLoad: (
    includeHiddenEmployees: boolean,
    from?: RangeDate,
    until?: RangeDate
  ) =>
    dispatch(
      loadReportOneOffDepartmentsAndUsers(includeHiddenEmployees, from, until)
    ),
})

interface OwnProps {
  intl: IntlShape
}

interface InjectedProps {
  includeOneOff?: boolean
  from: RangeDate
  until: RangeDate
}

interface State {
  selectedDepartment?: number
  includeHiddenEmployees: boolean
  checkedTreeNodes: {
    checked: number[]
    halfChecked: number[]
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type Props = ConnectedProps<typeof connector> & OwnProps & InjectedProps

class GroupedUserSelect extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      selectedDepartment: undefined,
      includeHiddenEmployees: false,
      checkedTreeNodes: {
        checked: [],
        halfChecked: [],
      },
    }
  }

  componentDidMount() {
    void this.loadStep1()
  }

  componentWillUnmount(): void {
    this.props.dispatchSet(undefined)
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const clientIdChanged =
      this.props.oucontext?.clientId &&
      this.props.oucontext.clientId !== prevProps.oucontext?.clientId
    const includeHiddenEmployeesChanged =
      this.state.includeHiddenEmployees !== prevState.includeHiddenEmployees
    const dateRangeChanged =
      !isEqual(this.props.from, prevProps.from) ||
      !isEqual(this.props.until, prevProps.until)
    if (clientIdChanged || includeHiddenEmployeesChanged || dateRangeChanged) {
      this.loadStep1()
    } else if (
      this.props.step1Updated !== prevProps.step1Updated &&
      this.props.step1Updated !== undefined
    ) {
      this.loadStep2()
    }
  }

  loadStep1(): void {
    if (this.props.oucontext.orgunitId !== undefined) {
      this.props.dispatchLoad(
        this.state.includeHiddenEmployees,
        this.props.tz,
        this.props.from,
        this.props.until
      )
    }
  }

  loadStep2(): void {
    if (
      this.props.oucontext.orgunitId !== undefined &&
      this.props.includeOneOff
    ) {
      this.props.dispatchOneOffLoad(
        this.state.includeHiddenEmployees,
        this.props.from,
        this.props.until
      )
    }
  }

  render() {
    const translate = (id: string) => this.props.intl.formatMessage({ id })

    /**
     * Cleans up the controlled tree checkboxes:
     * - If orga was checked -> full check on itself and all children.
     * - If orga was unchecked -> uncheck self and all children.
     * - Also parents:
     *   - If orga was checked -> All parents *could* be full checked,
     *     but must be at least half checked.
     *   - If orga was unchecked -> All parents can *at most* be half checked,
     *     but might also be unchecked.
     */
    const onOECheck = (checked: number[]) => {
      const wasRemoved =
        this.state.checkedTreeNodes.checked.length > checked.length
      let oId: number | undefined
      if (wasRemoved) {
        // Find the removed orga id
        const diff = this.state.checkedTreeNodes.checked.filter(
          sc => !checked.includes(sc)
        )
        if (diff.length === 1) {
          oId = diff[0]
        }
      } else {
        // Find the added orga id
        const diff = checked.filter(
          sc => !this.state.checkedTreeNodes.checked.includes(sc)
        )
        if (diff.length === 1) {
          oId = diff[0]
        }
      }
      if (oId) {
        const nodes = this.props.hierarchy.find(oId).getSubtreeAsArray()
        const nodeIds = nodes.map(c => c.id) as number[]
        const departments = this.props.departments.filter(
          d => nodeIds.indexOf(d.id) !== -1
        )
        const newSelectedDepartment = oId
        const isCheckedSet = new Set(this.state.checkedTreeNodes.checked)
        const isHalfCheckedSet = new Set(
          this.state.checkedTreeNodes.halfChecked
        )
        let newReportDepartmentsAndUsers = {}
        const parents = this.props.hierarchy
          .find(oId)
          .getPathToRootNode()
          .filter(p => p.getId() !== oId) as Hierarchy[]
        if (wasRemoved) {
          // Un-check this and all children and clean this.state.reportDepartmentsAndUsers
          nodeIds.forEach(id => {
            isCheckedSet.delete(id)
            isHalfCheckedSet.delete(id)
          })
          parents.forEach(parent => {
            const parentId = parent.getId() as number
            const parentIsHalfChecked = atLeastOneOtherHasHalfSelection(
              parentId,
              oId,
              this.props
            )
            if (parentIsHalfChecked) {
              isCheckedSet.delete(parentId)
              isHalfCheckedSet.add(parentId)
            } else {
              isCheckedSet.delete(parentId)
              isHalfCheckedSet.delete(parentId)
            }
          })
          newReportDepartmentsAndUsers = Object.assign(
            {},
            this.props.reportDepartmentsAndUsers
          )
          nodeIds.forEach(nId => {
            delete newReportDepartmentsAndUsers[nId]
          })
        } else {
          // Fully check this and all children and add users to this.state.reportDepartmentsAndUsers
          // For isCurrentlyHalfChecked and unchecked state.
          nodeIds.forEach(id => {
            isCheckedSet.add(id)
            isHalfCheckedSet.delete(id)
          })
          parents.forEach(parent => {
            const parentId = parent.getId() as number
            const parentIsHalfChecked = isNotFullySelected(parentId, this.props)
            if (parentIsHalfChecked) {
              isCheckedSet.delete(parentId)
              isHalfCheckedSet.add(parentId)
            } else {
              isCheckedSet.add(parentId)
              isHalfCheckedSet.delete(parentId)
            }
          })
          newReportDepartmentsAndUsers = {
            ...this.props.reportDepartmentsAndUsers,
            ...departments.reduce((acc, d) => {
              acc[d.id] = d.users.map(u => u.id)
              return acc
            }, {}),
          }
        }
        this.setState(
          {
            selectedDepartment: newSelectedDepartment,
            checkedTreeNodes: {
              checked: [...isCheckedSet],
              halfChecked: [...isHalfCheckedSet],
            },
          },
          () => this.props.dispatchSet(newReportDepartmentsAndUsers)
        )
      }
    }
    /*

    function Bla(props)
    {
       const [selected, select] = React.useState<{
        checked: number[]
        halfChecked: number[]
       }>({
        checked: [], halfChecked: []
       })
       const [value, setValue] = React.useState<string>()
       return <>
       <GroupedUserSelect
        selected={selected}
        select={select}
       ></GroupedUserSelect>
       <input value={value} onChange={setValue} />
</>
    }

    */

    const onOESelect = (selected: number[]) => {
      this.setState({ selectedDepartment: selected[0] })
    }

    /**
     * Cleans up the controlled tree checkboxes:
     * - If userIds is empty -> set to uncheckd or half-checked
     *   depending if the child oes have still checked users.
     * - If userIds is not empty check if all users of this oe are selected
     *   and set a full or half checked mark.
     * - Reflect the change to the check mark for the relevantDepartmentId
     *   to all parents. Mark full or half.
     */
    const onUserCheck = (
      userIds: number[],
      relevantDepartmentId: number,
      openDepartmentId: number
    ) => {
      const department = this.props.departments.find(
        d => d.id === relevantDepartmentId
      )
      const changedToUnchecked = userIds.length === 0
      const isSelfFullChecked = userIds.length === department?.users.length
      const isSelfHalfChecked =
        !isSelfFullChecked &&
        userIds.length > 0 &&
        department?.users.length > userIds.length
      const childrenHaveAtLeastOneHalfSelectedUser =
        atLeastOneChildHasHalfSelection(relevantDepartmentId, this.props)
      const isChecked = !changedToUnchecked && isSelfFullChecked
      const isHalfChecked =
        isSelfHalfChecked || childrenHaveAtLeastOneHalfSelectedUser

      const parents = this.props.hierarchy
        .find(relevantDepartmentId)
        .getPathToRootNode()
        .filter(p => p.getId() !== relevantDepartmentId) as Hierarchy[]
      const isCheckedSet = new Set(this.state.checkedTreeNodes.checked)
      const isHalfCheckedSet = new Set(this.state.checkedTreeNodes.halfChecked)
      if (isHalfChecked) {
        isHalfCheckedSet.add(relevantDepartmentId)
        // If this node is half checked, the parents *are* only half checked
        for (const parent of parents) {
          const parentId = parent.getId() as number
          isHalfCheckedSet.add(parentId)
          isCheckedSet.delete(parentId)
        }
      } else if (isChecked) {
        isCheckedSet.add(relevantDepartmentId)
        isHalfCheckedSet.delete(relevantDepartmentId)
        // If this node is checked, the parents *might* be checked or at least half-checked
        for (const parent of parents) {
          const parentId = parent.getId() as number
          const parentIsHalfChecked = isNotFullySelected(parentId, this.props)
          if (parentIsHalfChecked) {
            isCheckedSet.delete(parentId)
            isHalfCheckedSet.add(parentId)
          } else {
            isCheckedSet.add(parentId)
            isHalfCheckedSet.delete(parentId)
          }
        }
      } else {
        isCheckedSet.delete(relevantDepartmentId)
        isHalfCheckedSet.delete(relevantDepartmentId)
        // If this node is unchecked, the parents *might* be half- or un-checked.
        for (const parent of parents) {
          const parentId = parent.getId() as number
          const parentIsHalfChecked = atLeastOneOtherHasHalfSelection(
            parentId,
            relevantDepartmentId,
            this.props
          )
          if (parentIsHalfChecked) {
            isCheckedSet.delete(parentId)
            isHalfCheckedSet.add(parentId)
          } else {
            isCheckedSet.delete(parentId)
            isHalfCheckedSet.delete(parentId)
          }
        }
      }
      this.setState(
        {
          selectedDepartment: openDepartmentId,
          checkedTreeNodes: {
            checked: [...isCheckedSet],
            halfChecked: [...isHalfCheckedSet],
          },
        },
        () => {
          if (userIds.length > 0) {
            this.props.dispatchSet({
              ...this.props.reportDepartmentsAndUsers,
              [relevantDepartmentId]: userIds.length > 0 ? userIds : undefined,
            })
          } else {
            const newReportDepartmentsAndUsers = Object.assign(
              {},
              this.props.reportDepartmentsAndUsers
            )
            delete newReportDepartmentsAndUsers[relevantDepartmentId]
            this.props.dispatchSet(newReportDepartmentsAndUsers)
          }
        }
      )
    }

    const getCurrentContextPath = (): number[] => {
      const context = this.props.oucontext.orgunitId
      const hierarchy = this.props.hierarchy
      if (hierarchy === null || hierarchy === undefined) {
        return []
      }
      const contextNode = hierarchy.find(context) as Hierarchy
      if (contextNode === null || contextNode === undefined) {
        return []
      }
      const path = contextNode.getPathToRootNode() as Hierarchy[]
      return path.map(n => n.getId() as number)
    }

    const toggleHiddenEmployees = () => {
      const toggled = !this.state.includeHiddenEmployees
      this.setState({ includeHiddenEmployees: toggled })
    }

    const openDepartment = this.props.departments.find(
      d => d.id === this.state.selectedDepartment
    )

    const enabledIds = this.props.departments.map(d => d.id)

    const tree = (
      <Tree
        checkStrictly={true}
        checkable={true}
        showIcon={false}
        showLine={true}
        onCheck={(props: { checked: number[]; halfChecked: number[] }) => {
          onOECheck(props.checked)
        }}
        checkedKeys={this.state.checkedTreeNodes}
        onSelect={(ids: number[]) => onOESelect(ids)}
        defaultExpandedKeys={getCurrentContextPath()}
        treeData={createTreeData(
          [this.props.hierarchy],
          enabledIds,
          this.props.departments,
          this.props.reportDepartmentsAndUsers,
          translate
        )}
      />
    )

    const userSelect = (
      <div>
        {openDepartment
          ? buildCheckboxGroups(
              openDepartment,
              this.props.departments,
              this.props.hierarchy,
              this.props.reportDepartmentsAndUsers,
              translate,
              onUserCheck
            )
          : null}
      </div>
    )

    return (
      <Space align="start" size={150}>
        <div style={{ minWidth: "350px" }}>
          <label className="ua-label">
            {translate("groupedUserSelect.chooseOrgUnits")}
          </label>
          {tree}
        </div>
        <div>
          {userSelect}
          <br />
          <div style={{ marginBottom: "0.5em" }}>
            <Checkbox onClick={toggleHiddenEmployees}>
              {translate("groupedUserSelect.includeHiddenEmployees")}
            </Checkbox>
          </div>
        </div>
      </Space>
    )
  }
}

const connected = connector(injectIntl(GroupedUserSelect))
export default connected
