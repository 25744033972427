/**
 * Calculate the distance between two times; these must be within 24 hours
 * of each other (technically 23.99999...), but they *may* cross midnight
 *
 * Since PlannerTime only has minute precision, the result won't pretend
 * to be more precise than that.
 */

export function getMinutesBetween(t1: string, t2: string): number {
  const normalize = (s: string) => s.padStart(5, "0")
  const getHour = (s: string) => parseInt(s.substring(0, 2), 10)
  const getMinute = (s: string) => parseInt(s.substring(3, 5), 10)
  t1 = normalize(t1)
  t2 = normalize(t2)
  if (t2 === t1) {
    return 0
  } else if (t2 < t1) {
    return getMinutesBetween(t1, "24:00") + getMinutesBetween("00:00", t2)
  } else if (getHour(t1) === getHour(t2)) {
    return getMinute(t2) - getMinute(t1)
  } else {
    const minutes = 60 - getMinute(t1) + getMinute(t2)
    const hours = getHour(t2) - getHour(t1) - 1
    return hours * 60 + minutes
  }
}
