import React, { useEffect, useState } from "react"
import type { ReactElement } from "react"
import { useIntl } from "react-intl"
import type { IntlShape } from "react-intl"
import { DatePicker, Select, Space } from "antd"
import type { DatePickerProps } from "antd"
import dayjs from "dayjs"
import moment from "moment"
import type { Moment } from "moment"

import getLocale from "../../apps/shared/getLocale"
import { AntdLocaleWrapper } from "./AntdLocaleWrapper"

const { Option } = Select

export type PickerType = "date" | "week" | "month" | "year"

const PickerWithType = ({
  type,
  value,
  onChange,
  disabledDate,
}: {
  type: PickerType
  value: ReportDate
  onChange: DatePickerProps["onChange"]
  disabledDate: (date: dayjs.Dayjs) => boolean
}) => {
  const intl = useIntl()
  const pickerLocale = getLocale(intl.locale)
  let format = pickerLocale.dateFormat
  switch (type) {
    case "week":
      format = pickerLocale.weekFormat
      break
    case "month":
      format = pickerLocale.monthFormat
      break
    case "year":
      format = "YYYY"
      break
    case "date":
    default:
      break
  }
  const defaultValue = dayjs()
    .set("year", value.year)
    .set("month", value.month)
    .set("date", value.day)
  return (
    <DatePicker
      allowClear={false}
      defaultValue={defaultValue}
      value={defaultValue}
      picker={type}
      onChange={onChange}
      disabledDate={disabledDate}
      format={format}
    />
  )
}

export interface ReportDate {
  year: number
  month: number
  day: number
}

interface Props {
  intl: IntlShape
  lang: string
  maximumRangeInMonths: number
  defaultType?: PickerType
  allowTypeSelect?: boolean
  fromChange: (from: ReportDate) => void
  untilChange: (until: ReportDate) => void
}

export default function RangePickerWithType({
  intl,
  lang,
  maximumRangeInMonths,
  defaultType = "month",
  allowTypeSelect = true,
  fromChange,
  untilChange,
}: Props): ReactElement {
  const now = moment()
  const secondNow = now.clone()
  const [type, setType] = useState<PickerType>(defaultType)
  const [from, setFrom] = useState<ReportDate>({
    year: now.year(),
    month: now.month(),
    day: now.startOf("month").date(),
  })
  const [until, setUntil] = useState<ReportDate>({
    year: secondNow.year(),
    month: secondNow.month(),
    day: secondNow.endOf("month").date(),
  })

  const extractReportDate = React.useCallback(
    (date: Moment, atStart: boolean = true): ReportDate => {
      // Correct defaults for type = "date"
      let year = date.year()
      let month = date.month()
      let day = date.date()
      // Depending on the type we need to correct some stuff
      if (type === "week") {
        const weekStartMo = lang === "de" // moment.locale alternative
        const relevantDay = atStart
          ? date.weekday(weekStartMo ? 1 : 0)
          : date.weekday(weekStartMo ? 7 : 6)
        year = relevantDay.year()
        month = relevantDay.month()
        day = relevantDay.date()
      } else if (type === "month") {
        const relevantDay = atStart
          ? date.clone().startOf("month")
          : date.clone().endOf("month")
        year = relevantDay.year()
        day = relevantDay.date()
      } else if (type === "year") {
        month = atStart ? 0 : 11
        day = atStart ? 1 : 31
      }
      return { year, month, day }
    },
    [lang, type]
  )

  const selectFrom = React.useCallback(
    (date: Moment) => {
      const newFrom = extractReportDate(date, true)
      setFrom(newFrom)
      fromChange(newFrom)
      // Set the until date here as well if the type is 'year',
      // no second date widget will be rendered
      if (type === "year") {
        const newUntil = {
          year: newFrom.year,
          month: 11,
          day: 31,
        }
        setUntil(newUntil)
        untilChange(newUntil)
      } else {
        const untilMoment = reportDateToMoment(until)
        if (untilMoment.isBefore(date)) {
          const newUntil = extractReportDate(date, false)
          setUntil(newUntil)
          untilChange(newUntil)
        }
      }
    },
    [extractReportDate, fromChange, type, until, untilChange]
  )

  const selectUntil = React.useCallback(
    (date: Moment) => {
      const newUntil = extractReportDate(date, false)
      setUntil(newUntil)
      untilChange(newUntil)
    },
    [extractReportDate, untilChange]
  )

  const previousTypeRef = React.useRef<PickerType>()
  useEffect(() => {
    const prevType = previousTypeRef.current
    previousTypeRef.current = type
    if (type !== prevType) {
      selectFrom(moment())
      selectUntil(moment())
    }
  }, [selectFrom, selectUntil, type])

  const previousFromRef = React.useRef<ReportDate>()
  useEffect(() => {
    const prevFrom = previousFromRef.current
    previousFromRef.current = from
    if (!eql(prevFrom, from)) {
      fromChange(from)
    }
  }, [from, fromChange])

  const previousUntilRef = React.useRef<ReportDate>()
  useEffect(() => {
    const prevUntil = previousUntilRef.current
    previousUntilRef.current = until
    if (!eql(prevUntil, until)) {
      untilChange(until)
    }
  }, [until, untilChange])

  const changeType = React.useCallback(
    (choice: PickerType) => {
      setType(choice)
    },
    [setType]
  )

  const disabledFromDate = React.useCallback((selected: Moment) => {
    return selected && selected > moment().add(1, "years").endOf("year")
  }, [])

  // We forbid selections over 12 months
  const disabledUntilDate = React.useCallback(
    (selected: Moment) => {
      const fromMoment = reportDateToMoment(from)
      const outOfCacheBound = disabledFromDate(selected)
      const outOfYearBound =
        selected &&
        selected.isAfter(
          fromMoment.clone().add(maximumRangeInMonths, "months").endOf("month")
        )
      const beforeFromBound =
        selected && selected.isBefore(fromMoment.clone().startOf("day"))
      return outOfCacheBound || outOfYearBound || beforeFromBound
    },
    [disabledFromDate, from, maximumRangeInMonths]
  )

  return (
    <AntdLocaleWrapper>
      <Space size="middle">
        {allowTypeSelect && (
          <div>
            <div className="report-form-field-label">
              {intl.formatMessage({ id: "userReports.type" })}
            </div>
            <Select
              value={type}
              onChange={changeType}
              style={{ minWidth: 100 }}
            >
              <Option value="date">
                {intl.formatMessage({ id: "userReports.Date" })}
              </Option>
              <Option value="week">
                {intl.formatMessage({ id: "userReports.Week" })}
              </Option>
              <Option value="month">
                {intl.formatMessage({ id: "userReports.Month" })}
              </Option>
              <Option value="year">
                {intl.formatMessage({ id: "userReports.Year" })}
              </Option>
            </Select>
          </div>
        )}
        <div>
          <div className="report-form-field-label">
            {intl.formatMessage({ id: "userReports.from" })}
          </div>
          <PickerWithType
            type={type}
            value={from}
            onChange={v => selectFrom(moment(v.toDate()))}
            disabledDate={d => disabledFromDate(moment(d.toDate()))}
          />
        </div>
        {type !== "year" && (
          <div>
            <div className="report-form-field-label">
              {intl.formatMessage({ id: "userReports.to" })}
            </div>
            <PickerWithType
              type={type}
              value={until}
              onChange={v => selectUntil(moment(v.toDate()))}
              disabledDate={d => disabledUntilDate(moment(d.toDate()))}
            />
          </div>
        )}
      </Space>
    </AntdLocaleWrapper>
  )
}

function eql(d1?: ReportDate, d2?: ReportDate): boolean {
  if (
    (d1 === undefined && d2 !== undefined) ||
    (d2 === undefined && d1 !== undefined)
  ) {
    return false
  }
  return d1.year === d2.year && d1.month === d2.month && d1.day === d2.day
}

function reportDateToMoment(reportDate: ReportDate): Moment {
  return moment()
    .set("year", reportDate.year)
    .set("month", reportDate.month)
    .set("date", reportDate.day)
}
