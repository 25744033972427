import React from "react"
import { Checkbox } from "antd"

import ColorBullet from "../../../apps/shared/ColorBullet/ColorBullet"

import type Hierarchy from "../../models/Hierarchy"

import type {
  Department,
  ReportDepartmentsAndUsers,
  SelectionProps,
  TranslateFn,
  TreeNodesData,
} from "./interfaces"

export const createTreeData = (
  nodes: Hierarchy[],
  enabledIds: number[],
  departments: Department[],
  selected: ReportDepartmentsAndUsers,
  translate: TranslateFn
): TreeNodesData[] => {
  return nodes
    .map(node => {
      if (node === null) {
        return undefined
      } else {
        return {
          title: generateNodeTitle(node, departments, selected, translate),
          key: node.getId(),
          disabled: enabledIds.indexOf(node.getId() as number) === -1,
          children: createTreeData(
            node.getChildren() as Hierarchy[],
            enabledIds,
            departments,
            selected,
            translate
          ),
        }
      }
    })
    .filter(n => !!n)
}

function generateNodeTitle(
  node: Hierarchy,
  departments: Department[],
  selected: ReportDepartmentsAndUsers,
  translate: TranslateFn
): JSX.Element {
  const nodeId = node.getId()
  const name = node.getName()
  const department = departments.find(d => d.id === nodeId)
  const userCount = department?.users.length
  const selectedCount = selected[nodeId]?.length ?? 0
  return (
    <span className="reportBullet">
      <span>
        <ColorBullet color={node.getColorAsObject()} large={true} />
      </span>
      <span>
        {`${name} (${selectedCount} / ${userCount || 0} ${translate(
          "groupedUserSelect.totalUsers"
        )})`}
      </span>
    </span>
  )
}

export function buildCheckboxGroups(
  openDepartment: Department,
  departments: Department[],
  hierarchy: Hierarchy,
  reportDepartmentsAndUsers: ReportDepartmentsAndUsers,
  translate: TranslateFn,
  onUserCheck: (
    userIds: number[],
    relevantDepartmentId: number,
    openDepartmentId: number
  ) => void
): JSX.Element[] {
  const childNodes = hierarchy.find(openDepartment.id).getSubtreeAsArray()
  const childIds = childNodes.map(c => c.id)
  const visibleDepartments = departments
    .filter(d => childIds.indexOf(d.id) !== -1)
    .reverse()
  return visibleDepartments.map(vd => {
    const selected = reportDepartmentsAndUsers[vd.id]
    return (
      <div key={vd.id}>
        <Checkbox.Group
          onChange={(userIds: number[]) => {
            onUserCheck(userIds, vd.id, openDepartment.id)
          }}
          value={selected}
        >
          <div>{makeUserOptions(vd, translate)}</div>
        </Checkbox.Group>
      </div>
    )
  })
}

function makeUserOptions(department: Department, translate: TranslateFn) {
  const makeLabel = (d: Department) => (
    <div>
      <label className="ua-label">
        {d ? `${d.name}: ` : ""}
        {translate("groupedUserSelect.chooseUsers")}
      </label>
    </div>
  )
  const makeUserCheckboxes = (d: Department) =>
    d.users.map(u => (
      <div className="user-item" key={u.id}>
        <Checkbox value={u.id}>
          {u.fullnameAndSalutation || u.username}
        </Checkbox>
      </div>
    ))
  return (
    <>
      {makeLabel(department)}
      <div className="user-container">{makeUserCheckboxes(department)}</div>
      <br />
    </>
  )
}

/*
function findSelectedDepartment(
  selected: number[],
  state: State
): number | undefined {
  const oldIds = Object.keys(state.reportDepartmentsAndUsers).map(id =>
    parseInt(id, 10)
  )
  const newId = selected.filter(id => oldIds.indexOf(id) === -1)
  const removedId = oldIds.filter(id => selected.indexOf(id) === -1)
  const selectedDepartment =
    newId.length > 0
      ? newId[0]
      : removedId.length > 0
      ? removedId[0]
      : state.selectedDepartment
  return selectedDepartment
}
*/

export function atLeastOneChildHasHalfSelection(
  departmentId: number,
  props: SelectionProps
): boolean {
  const childrenNodes = props.hierarchy
    .find(departmentId)
    .getSubtreeAsArray()
    .filter(c => c.id !== departmentId)
  const childIds = childrenNodes.map((c: Hierarchy) => c.id) as number[]
  const childDepartments = props.departments.filter(d =>
    childIds.includes(d.id)
  )
  return childDepartments.some(cd => {
    const childCheckedLen = props.reportDepartmentsAndUsers[cd.id]?.length
    return (
      cd.users.length > 0 &&
      childCheckedLen > 0 &&
      cd.users.length > childCheckedLen
    )
  })
}

/*
function allUsersAreSelected(
  departmentId: number,
  props: Props,
): boolean {
  const department = props.departments.find(d => d.id === departmentId)
  return (
    props.reportDepartmentsAndUsers[departmentId].length ===
    department.users.length
  )
}
*/

export function isNotFullySelected(
  departmentId: number,
  props: SelectionProps
): boolean {
  const childrenNodes = props.hierarchy.find(departmentId).getSubtreeAsArray()
  const childIds = childrenNodes.map((c: Hierarchy) => c.id) as number[]
  const childDepartments = props.departments.filter(d =>
    childIds.includes(d.id)
  )
  return childDepartments.some(
    cd =>
      cd.users.length > 0 &&
      props.reportDepartmentsAndUsers[cd.id]?.length !== cd.users.length
  )
}

/**
 * Needs to ignore the branch of the changedChildId.
 */
export function atLeastOneOtherHasHalfSelection(
  departmentId: number,
  changedChildId: number,
  props: SelectionProps
): boolean {
  const ignoredChildren = props.hierarchy
    .find(changedChildId)
    .getSubtreeAsArray()
  const ignoredChildrenIds = ignoredChildren.map(n => n.getId())
  const childrenNodes = props.hierarchy
    .find(departmentId)
    .getSubtreeAsArray()
    .filter(n => !ignoredChildrenIds.includes(n.getId()))
  const childIds = childrenNodes.map((c: Hierarchy) => c.id) as number[]
  const childDepartments = props.departments.filter(d =>
    childIds.includes(d.id)
  )
  return childDepartments.some(cd => {
    const childCheckedLen = props.reportDepartmentsAndUsers[cd.id]?.length
    return (
      cd.users.length > 0 &&
      childCheckedLen !== undefined &&
      childCheckedLen > 0
    )
  })
}

export const mergeDepartments = (
  d1: Department[],
  d2: Department[]
): Department[] => {
  return d1.map(d => {
    const additionalUsers = d2.find(dd => dd.id === d.id)?.users
    const users = d.users
    if (additionalUsers) {
      additionalUsers.forEach(au => {
        if (users.find(u => u.id === au.id) === undefined) {
          users.push(au)
        }
      })
    }
    return {
      ...d,
      users,
    }
  })
}
