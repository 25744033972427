import { createSelector } from "reselect"

import { valueAt } from "../../../../shared/walkObjectHierarchy"
import type State from "../../../../redux/state"

import type { Department, ReportDepartmentsAndUsers } from "../interfaces"

// Wrap structure in model? Is it worth it?
export const getAllowedDepartmentsAndUsers = createSelector(
  (state: State) => valueAt(state, ["groupedUserSelect", "allowedDepartments"]),
  (departments: Department[]) => departments || []
)

export const getSelectedDepartmentsAndUsers = createSelector(
  (state: State) =>
    valueAt(state, ["groupedUserSelect", "selectedDepartmentsAndUsers"]),
  (departmentsAndUsers: ReportDepartmentsAndUsers) => departmentsAndUsers || {}
)
