import { ensureIntOrUndefined } from "../../../ensureInt"
import { valueAt, isEqual } from "../../../walkObjectHierarchy"
import types from "../actions/actionTypes"

export default function (state = {}, action) {
  switch (action.type) {
    case types.SELECTCLIENT:
      return {
        ...state,
        currentClientId: ensureIntOrUndefined(action.clientId),
        currentOrgUnitId: ensureIntOrUndefined(action.orgunitId),
      }
    case types.SELECTORGUNIT:
      return {
        ...state,
        currentOrgUnitId: ensureIntOrUndefined(action.orgunitId),
        currentClientId: ensureIntOrUndefined(state.currentClientId),
      }
    case types.LOADALLACTIVEORGUNITSFAILED:
      return {
        ...state,
        loadFailed: true,
        loadError: action.error,
      }
    case types.LOADALLACTIVEORGUNITS:
      return loadAllActive(state, "orgUnits", action.allOrgUnits)
    case types.LOADALLACTIVECLIENTS:
      return loadAllActive(state, "clients", action.allClients)
    default:
      return state
  }
}

function loadAllActive(state, subsliceName, newArray) {
  const oldArray = Object.values(valueAt(state, [subsliceName, "byId"]) || [])
  if (!isEqual(oldArray, newArray)) {
    return {
      ...state,
      [subsliceName]: {
        byId: newArray.reduce((acc, entry) => {
          acc[entry.id] = { ...entry }
          return acc
        }, {}),
      },
    }
  } else {
    return state
  }
}
